import { Box } from "components/Layout.component"
import { H1 } from "components/legacy/typography"
import { LiveDeal } from "features/deal/components/LiveDeal.component"
import { IBackendDealInfo } from "features/deal/types"
import React from "react"

import { ContributeButton } from "./ContributButton.component"

interface ILiveDealsLayoutProps {
  deals: IBackendDealInfo[]
}
export const LiveDealsContainer: React.FC<ILiveDealsLayoutProps> = ({ deals }) => {
  if (deals.length == 0) {
    return null
  }

  return (
    <Box mt={5} mb={5}>
      <H1 fontSize={{ _: "40px", tablet: "55px" }}>Live</H1>
      {deals.map((deal, i) => (
        <Box key={i} mt={5}>
          <LiveDeal key={i} deal={deal} contributeComponent={<ContributeButton deal={deal} />} />
        </Box>
      ))}
    </Box>
  )
}
