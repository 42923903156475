import { useHover } from "@mantine/hooks"
import { DealBadge } from "components/DealBadge.component"
import { Flex, Box } from "components/Layout.component"
import { Text, Title } from "components/Typography.component"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { IBackendDealInfo } from "features/deal/types"
import { convertImageToMaxSizes } from "helpers/images"
import React from "react"
import { FaDotCircle, FaClock, FaCheckCircle } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { PAGE_URLS } from "routes"
import styled from "styled-components"
import { theme, ColorKeys } from "theme"

interface IProjectCardProps {
  project: IBackendDealInfo
  fullWidth: boolean
}
const Logo = styled.img`
  max-width: 80px;
  max-height: 80px;
`

interface IStatusProps {
  hovered: boolean
  Icon: React.ComponentType<any>
  text: string
  color: ColorKeys
}

const Status: React.FC<IStatusProps> = ({ hovered, Icon, text, color }) => {
  return (
    <Flex alignItems="center">
      <Icon color={hovered ? theme.colors.white : theme.colors[color]} />
      <Box ml={1}>
        <Text
          color={hovered ? "white" : color}
          opacity={1}
          fontWeight={text.toLowerCase() === "live" ? "bold" : "normal"}
          variant="small"
          lineHeight="100%"
        >
          {text}
        </Text>
      </Box>
    </Flex>
  )
}

const Live: React.FC<{ hovered: boolean }> = ({ hovered }) => (
  <Status hovered={hovered} text="Live" Icon={FaDotCircle} color="live" />
)

const Upcoming: React.FC<{ hovered: boolean }> = ({ hovered }) => (
  <Status hovered={hovered} text="Upcoming" Icon={FaClock} color="upcoming" />
)
const Funded: React.FC<{ hovered: boolean }> = ({ hovered }) => (
  <Status hovered={hovered} text="Funded" Icon={FaCheckCircle} color="primary" />
)

const ProjectCardContainer = styled(Flex)`
  cursor: pointer;
`

export const ProjectCard: React.FC<IProjectCardProps> = ({
  project,

  fullWidth,
}) => {
  const { name, started, ended, logoUrl, isIdo } = project
  const { hovered, ref } = useHover()
  const StatusComponent = ended ? Funded : !started ? Upcoming : Live
  const navigate = useNavigate()

  return (
    <ProjectCardContainer
      p={3}
      ref={ref}
      flexDirection="column"
      bg={hovered ? "secondary" : "backgroundSecondary"}
      borderRadius={16}
      width={{ _: fullWidth ? "100%" : "280px" }}
      height="280px"
      boxShadow={hovered ? "0px 48px 48px rgba(0, 85, 255, 0.24)" : undefined}
      onClick={() => {
        navigate(project.isIdo ? PAGE_URLS.LAUNCHPAD : PAGE_URLS.PRIVATE_DEALS)
      }}
    >
      <Flex alignItems="baseline" justifyContent="space-between">
        <StatusComponent hovered={hovered} />
        <Flex flexDirection="column" alignItems="flex-end">
          <DealBadge isIdo={isIdo} />
        </Flex>
      </Flex>
      <Flex
        mx="auto"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box width="80px" height="80px">
          {logoUrl && (
            <Logo src={convertImageToMaxSizes({ url: logoUrl, width: 80, height: 80 })} />
          )}
        </Box>
        <Box mt={2}>
          <Title variant="h5" textAlign="center">
            {name}
          </Title>
        </Box>
      </Flex>
      <Title color="primary" variant="h6" textAlign="center">
        {!project.started &&
          project.desiredStartTime &&
          `${formatDistanceToNowStrict(new Date(project.desiredStartTime))} from now`}
      </Title>
    </ProjectCardContainer>
  )
}
