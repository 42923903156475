import React from "react"
import styled from "styled-components"
import { border, space } from "styled-system"

interface ITextInputProps extends Omit<React.HTMLProps<HTMLInputElement>, "ref" | "as"> {
  value: string | number
  error?: boolean
  fontSize?: string
  align?: "right" | "left"
  fullWidth?: boolean
}

interface IRawInputProps {
  fullWidth?: boolean
  disabled?: boolean
}

const RawInput = styled.input<IRawInputProps>`
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-style: none;
  font-family: inherit;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5rem;
  padding: 0.75rem;
  background-color: #4D2154;
  border-radius: 0.625rem;
  line-height: inherit;
  color: #ffffff;
  ${(props) => props.fullWidth && "width: 100%;"}
  ${(props) => props.disabled && "background-color: #48244E; cursor: not-allowed; opacity: 0.5;"}
  &:focus {
      --ring-offset-shadow: 0 0 0 0px #fff;
      --ring-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
      box-shadow: var(--ring-offset-shadow),var(--ring-shadow),0 0 transparent;
    }
  }
  ${space};
  ${border};
`

export const TextInput = React.memo(
  ({ value, placeholder, fullWidth, disabled, ...rest }: ITextInputProps) => {
    return (
      <RawInput
        {...rest}
        value={value}
        // universal input options
        autoComplete="off"
        autoCorrect="off"
        // text-specific options
        type="text"
        placeholder={placeholder || ""}
        spellCheck="false"
        fullWidth={fullWidth}
        disabled={disabled}
      />
    )
  }
)

TextInput.displayName = "TextInput"
