import { ENTITIES, FEATURES, PROVIDERS } from "constants/queryKeys"

import { useWeb3React } from "@web3-react/core"
import { BigNumber } from "ethers"
import { isAddress, useContract } from "helpers/useContract.hook"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"

import { isLive } from "./selectors"
import { IBackendDealInfo } from "./types"
import { getAbiAndServiceForContractFactory } from "./utils"

interface IContractAddresses {
  contractAddress: string
  contractFactoryAddress: string
}
interface IContractInteraction extends IContractAddresses {
  account?: string | null
}

export const dealContractKeys = {
  all: [{ feature: FEATURES.DEAL_CONTRACT, provider: PROVIDERS.WEB3 }] as const,
  detail: {
    all: ({ contractAddress, contractFactoryAddress }: IContractAddresses) =>
      [
        {
          ...dealContractKeys.all[0],
          contractAddress,
          contractFactoryAddress,
          entity: ENTITIES.ITEM,
        },
      ] as const,
    contribution: ({ contractAddress, contractFactoryAddress, account }: IContractInteraction) =>
      [
        {
          ...dealContractKeys.detail.all({ contractAddress, contractFactoryAddress })[0],
          account,
          variable: "contribution",
        },
      ] as const,
    personalMax: ({ contractAddress, contractFactoryAddress, account }: IContractInteraction) =>
      [
        {
          ...dealContractKeys.detail.all({ contractAddress, contractFactoryAddress })[0],
          account,
          variable: "personalMax",
        },
      ] as const,
    totalRaised: ({ contractAddress, contractFactoryAddress }: IContractAddresses) =>
      [
        {
          ...dealContractKeys.detail.all({ contractAddress, contractFactoryAddress })[0],
          variable: "totalRaised",
        },
      ] as const,
  },
}

// Deals on the backend are created by an 'allocation factory' contract. Over
// time this contract has been changed and as a result so has its API. Here we
// use the contract factory address of a deal to figure out which contract/abi
// to instantiate
export const useContractFromFactory = (contractAddress: string, contractFactoryAddress: string) => {
  invariant(isAddress(contractAddress), "Deal contract address is invalid")
  invariant(isAddress(contractFactoryAddress), "Deal contract factory address is invalid")

  const { abi, service } = getAbiAndServiceForContractFactory(contractFactoryAddress)
  const contract = useContract(contractAddress, abi)

  if (contract) {
    return new service(contract)
  }
  return null
}

export const useContribution = ({
  contractAddress,
  contractFactoryAddress,
  started,
  ended,
}: Pick<IBackendDealInfo, "contractAddress" | "contractFactoryAddress" | "started" | "ended">) => {
  const { account } = useWeb3React()
  const service = useContractFromFactory(contractAddress, contractFactoryAddress)

  return useQuery<BigNumber>(
    dealContractKeys.detail.contribution({ contractAddress, contractFactoryAddress, account }),
    async () => {
      invariant(account, "Account not given")
      invariant(service, "Service not given")

      return service.getContribution(account)
    },
    {
      // users cannot contribute to deals that have not been started so no need
      // to check otherwise
      enabled: Boolean(account) && Boolean(service) && started,
      // once a deal has ended a users contribution can never change
      staleTime: ended ? Infinity : 0,
    }
  )
}

export const usePersonalMax = ({
  contractAddress,
  contractFactoryAddress,
  ended,
  started,
}: Pick<IBackendDealInfo, "contractAddress" | "contractFactoryAddress" | "ended" | "started">) => {
  const { account } = useWeb3React()
  const service = useContractFromFactory(contractAddress, contractFactoryAddress)

  return useQuery<BigNumber>(
    dealContractKeys.detail.personalMax({ contractAddress, contractFactoryAddress, account }),
    async () => {
      invariant(account, "Account not given")
      invariant(service, "Service not given")

      return service.getPersonalMax(account)
    },
    {
      // users only have a personal max whilst the deal is live
      enabled: Boolean(account) && Boolean(service) && isLive({ started, ended }),
      // we could make this more complicated based on information we know (such
      // as startedAt), but the eventual consistency of the blockchain makes it
      // complicated. Instead we just keep refreshing
      refetchInterval: 10_000,
    }
  )
}

export const useTotalRaised = ({
  contractAddress,
  contractFactoryAddress,
  started,
  ended,
  totalRaised,
}: Pick<
  IBackendDealInfo,
  "contractAddress" | "contractFactoryAddress" | "started" | "ended" | "totalRaised"
>) => {
  const service = useContractFromFactory(contractAddress, contractFactoryAddress)

  return useQuery<BigNumber>(
    dealContractKeys.detail.totalRaised({ contractAddress, contractFactoryAddress }),
    async () => {
      invariant(service, "Service not given")

      return service.totalRaised()
    },
    {
      // start with data from the backend
      initialData: totalRaised,
      // only fetch whilst deal is live
      enabled: Boolean(service) && isLive({ started, ended }),
      // updated every 15s
      refetchInterval: 15_000,
    }
  )
}
