import { sha512_256 } from "js-sha512"
import { base32 } from "rfc4648"

// adapted from https://github.com/Swyftx/crypto-address-validator/blob/dev/src/algo_validator.js

export function isAlgorandAddress(address: string) {
  if (address.length !== 58) {
    return false
  } else {
    // Decode base32 Address
    try {
      const decoded = Buffer.from(base32.parse(address + "=".repeat(6)))
      const addr = decoded.slice(0, decoded.length - 4)
      const checksum = decoded.slice(-4).toString("hex")

      // Hash Address - Checksum
      const hash = sha512_256.create()
      hash.update(addr)
      const code = hash.hex().slice(-8)
      return code === checksum
    } catch (err) {
      return false
    }
  }
}
