import { Flex, Box, Grid } from "components/Layout.component"
import { Text } from "components/Typography.component"
import React from "react"
import {
  BsFillFileEarmarkFill,
  BsLink45Deg,
  BsTelegram,
  BsTwitter,
  BsDiscord,
} from "react-icons/bs"

export interface IProjectLinks {
  projectTelegramUrl?: string
  projectTwitterUrl?: string
  projectWebsiteUrl?: string
  projectWhitepaperUrl?: string
  projectDiscordUrl?: string
  projectPitchDeckUrl?: string
}

export const ProjectLinks: React.FC<IProjectLinks> = ({
  projectTelegramUrl,
  projectTwitterUrl,
  projectWebsiteUrl,
  projectWhitepaperUrl,
  projectDiscordUrl,
  projectPitchDeckUrl,
}) => {
  const socials = []
  if (projectTelegramUrl) {
    socials.push(
      <a rel="noreferred noreferrer" target="_blank" href={projectTelegramUrl} key="telegram">
        <Flex
          alignItems="center"
          backgroundColor="backgroundSecondary"
          borderRadius="8px"
          px={2}
          py={1}
        >
          <BsTelegram size={24} color="#FFFFFF" />
        </Flex>
      </a>
    )
  }

  if (projectTwitterUrl) {
    socials.push(
      <a rel="noreferred noreferrer" target="_blank" href={projectTwitterUrl} key="twitter">
        <Flex
          alignItems="center"
          backgroundColor="backgroundSecondary"
          borderRadius="8px"
          px={2}
          py={1}
        >
          <BsTwitter size={24} color="#FFFFFF" />
        </Flex>
      </a>
    )
  }
  if (projectDiscordUrl) {
    socials.push(
      <a rel="noreferred noreferrer" target="_blank" href={projectDiscordUrl} key="discord">
        <Flex
          alignItems="center"
          backgroundColor="backgroundSecondary"
          borderRadius="8px"
          px={2}
          py={1}
        >
          <BsDiscord size={24} color="#FFFFFF" />
        </Flex>
      </a>
    )
  }

  const links = []

  if (projectWebsiteUrl) {
    let url
    try {
      url = new URL(projectWebsiteUrl).hostname
    } catch (error) {
      url = ""
    }
    if (url.length > 0) {
      links.push(
        <a rel="noreferred noreferrer" target="_blank" href={projectWebsiteUrl} key="website">
          <Flex
            alignItems="center"
            backgroundColor="backgroundSecondary"
            borderRadius="8px"
            pr={2}
            pl={1}
            py={1}
          >
            <BsLink45Deg size={24} color="#FFFFFF" />
            <Box ml={1}>
              <Text variant="smallsubtitle" color="textPrimary" opacity={1}>
                {url}
              </Text>
            </Box>
          </Flex>
        </a>
      )
    }
  }

  if (projectWhitepaperUrl) {
    links.push(
      <a rel="noreferred noreferrer" target="_blank" href={projectWhitepaperUrl} key="whitepaper">
        <Flex
          alignItems="center"
          backgroundColor="backgroundSecondary"
          borderRadius="8px"
          pr={2}
          pl={1}
          py={1}
        >
          <BsFillFileEarmarkFill size={24} color="#FFFFFF" />
          <Box ml={1}>
            <Text variant="smallsubtitle" color="textPrimary" opacity={1}>
              Whitepaper
            </Text>
          </Box>
        </Flex>
      </a>
    )
  }

  if (projectPitchDeckUrl) {
    links.push(
      <a rel="noreferred noreferrer" target="_blank" href={projectPitchDeckUrl} key="pitchdeck">
        <Flex
          alignItems="center"
          backgroundColor="backgroundSecondary"
          borderRadius="8px"
          pr={2}
          pl={1}
          py={1}
        >
          <BsFillFileEarmarkFill size={24} color="#FFFFFF" />
          <Box ml={1}>
            <Text variant="smallsubtitle" color="textPrimary" opacity={1}>
              Pitch Deck
            </Text>
          </Box>
        </Flex>
      </a>
    )
  }

  if (socials.length === 0 && links.length === 0) {
    return null
  }

  return (
    <Box mt={3}>
      {socials.length > 0 && (
        <Flex alignItems="center">
          {socials.map((component, i) => {
            if (i === 0) {
              return component
            }
            return (
              <Flex key={i} ml={{ _: 1 }} alignItems="center">
                {component}
              </Flex>
            )
          })}
        </Flex>
      )}

      {links.length > 0 && (
        <Grid
          mt={1}
          gridRowGap={1}
          gridColumnGap={2}
          gridTemplateColumns={{ _: "repeat(auto-fill, 200px)" }}
        >
          {links.map((component, i) => {
            return (
              <Flex key={i} alignItems="center">
                {component}
              </Flex>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}
