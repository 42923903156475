import type { DistributionChain } from "constants/distributionChains"

import { useMediaQuery } from "@mantine/hooks"
import { Box } from "components/Layout.component"
import { Tooltip } from "components/Tooltip.component"
import { convertImageToMaxSizes } from "helpers/images"
import { capitalise } from "helpers/text"
import React from "react"
import styled from "styled-components"
import { layout } from "styled-system"
import { theme } from "theme"

const getImageForChain = (chain?: DistributionChain) => {
  switch (chain) {
    case "SOLANA":
      return "https://safelaunch-public.s3.eu-west-2.amazonaws.com/logos/chains/solana.png"
    case "ALGORAND":
      return "https://safelaunch-public.s3.eu-west-2.amazonaws.com/logos/chains/algorand.png"
    case "ETH":
      return "https://safelaunch-public.s3.eu-west-2.amazonaws.com/logos/chains/ethereum.png"
    case "BSC":
      return "https://safelaunch-public.s3.eu-west-2.amazonaws.com/logos/chains/binance_smart_chain.png"
    case "AVAX":
      return "https://safelaunch-public.s3.eu-west-2.amazonaws.com/logos/chains/avalanche.png"
    case "FTM":
      return "https://safelaunch-public.s3.eu-west-2.amazonaws.com/logos/chains/fantom.png"
    case "POLYGON":
      return "https://safelaunch-public.s3.eu-west-2.amazonaws.com/logos/chains/polygon.png"
    default:
      return ""
  }
}

const ProjectLogo = styled.img<any>`
  height: auto;
  ${layout};
`

const Logo = styled.img`
  height: 32px;
  width: 32px;
`

export const ChainLogo: React.FC<{ chain?: DistributionChain; logoUrl?: string }> = ({
  chain,
  logoUrl,
}) => {
  const chainImage = getImageForChain(chain)
  const isTablet = useMediaQuery(theme.mediaQueries.tablet)
  const chainOffset = isTablet ? "85%" : "75%"

  return (
    <Box position="relative">
      {logoUrl && (
        <ProjectLogo
          src={convertImageToMaxSizes({ url: logoUrl, width: 120, height: 120 })}
          width={{ _: "77px", tablet: "120px" }}
        />
      )}
      {chain && (
        <Tooltip
          label={`Tokens released on ${capitalise(chain)}`}
          styles={{ root: { position: "absolute", top: chainOffset, left: chainOffset } }}
        >
          <Logo
            src={convertImageToMaxSizes({ url: chainImage, height: 32, width: 32 })}
            alt={`Tokens released on ${capitalise(chain)}`}
          />
        </Tooltip>
      )}
    </Box>
  )
}
