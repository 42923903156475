import { SFEX_ADDRESS } from "constants/addresses"
import { SAFELAUNCH_URLS } from "constants/urls"

import { useClipboard } from "@mantine/hooks"
import { Button } from "components/Button.component"
import { Flex, Box } from "components/Layout.component"
import { SocialLinks } from "components/SocialLinks.component"
import { Text, Title } from "components/Typography.component"
import { Showcase } from "features/showcase/components/Showcase.component"
import { snipAddress } from "helpers/text"
import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { AiOutlinePieChart } from "react-icons/ai"
import { BiCopy } from "react-icons/bi"
import { IoRocketOutline } from "react-icons/io5"
import { Link } from "react-router-dom"
import { PAGE_URLS } from "routes"

export const Hero: React.FC = () => {
  const clipboard = useClipboard({ timeout: 1000 })
  const { t } = useTranslation()
  return (
    <>
      <Flex flexDirection={{ _: "column", sm: "row" }}>
        <Flex flexDirection="column" alignItems="center" px={{ _: 0, sm: 10 }} mx="auto">
          <Box>
            <Title textAlign={{ _: "center" }} variant="h1">
              {t("Start investing in early-stage crypto projects with SafeLaunch")}
            </Title>
          </Box>

          <Flex flexDirection={{ _: "column-reverse", sm: "column" }} alignItems="center">
            <Flex
              mt={{ _: 4, sm: 4 }}
              flexDirection={{ _: "column", sm: "row" }}
              alignItems="center"
            >
              <Box>
                <a
                  rel="noreferred noreferrer"
                  target="_blank"
                  href={SAFELAUNCH_URLS.SFEX_PANCAKESWAP}
                >
                  <Button fullWidth large>
                    {t("Buy SFEX")}
                  </Button>
                </a>
              </Box>

              <Box ml={{ _: 0, sm: 3 }} mt={{ _: 2, sm: 0 }}>
                <a rel="noreferred noreferrer" target="_blank" href={SAFELAUNCH_URLS.TG}>
                  <Button outlined large fullWidth color="dark">
                    {t("Join the community")}
                  </Button>
                </a>
              </Box>

              <Box ml={{ _: 0, sm: 3 }} mt={{ _: 2, sm: 0 }}>
                <a rel="noreferred noreferrer" target="_blank" href={SAFELAUNCH_URLS.IDO_FORM}>
                  <Button leftIcon={<IoRocketOutline />} outlined large fullWidth color="dark">
                    {t("Apply as a project")}
                  </Button>
                </a>
              </Box>

              <Box ml={{ _: 0, sm: 3 }} mt={{ _: 2, sm: 0 }}>
                <Button
                  leftIcon={<BiCopy />}
                  outlined
                  large
                  color="dark"
                  onClick={() => clipboard.copy(SFEX_ADDRESS)}
                >
                  {clipboard.copied ? t("Copied") : snipAddress(SFEX_ADDRESS)}
                </Button>
              </Box>
            </Flex>

            <Flex
              mt={{ _: 2, sm: 4 }}
              alignItems="center"
              flexDirection="column"
              width={{ _: "100%", sm: "60%" }}
            >
              <Text textAlign={{ _: "center" }}>
                <Trans>
                  <span style={{ color: "white", opacity: 1, fontWeight: "bold" }}>SafeLaunch</span>{" "}
                  offers funding opportunities in the seed, private sale, and IDO rounds of
                  promising new projects. Invest in highly-vetted crypto projects historically
                  restricted to a select few. All on a safe & simple platform.
                </Trans>
              </Text>
            </Flex>
          </Flex>

          <Flex width={{ _: "100%", sm: "50%" }} justifyContent="center" mt={2}>
            <Box width={{ _: "50%" }}>
              <Trans>
                <Text textAlign="center" variant="bold" fontSize={4} shade="white">
                  {{ numProjects: "100+" }}
                </Text>
                <Text textAlign="center" shade="white">
                  Projects funded
                </Text>
              </Trans>
            </Box>
            <Box width={{ _: "50%" }}>
              <Trans>
                <Text textAlign="center" variant="bold" fontSize={4} shade="white">
                  {{ amountRaisedUsd: "$20m+" }}
                </Text>
                <Text textAlign="center" shade="white">
                  Raised in the last 6 months
                </Text>
              </Trans>
            </Box>
          </Flex>

          <SocialLinks />
        </Flex>
      </Flex>

      <Flex mt={{ _: 4 }} mx="auto" justifyContent="center">
        <Link style={{ width: "100%" }} to={PAGE_URLS.HOW_TO_JOIN}>
          <Button
            large
            fullWidth
            color="dark"
            outlined
            leftIcon={<AiOutlinePieChart color="#22CC93" />}
          >
            {t("Start investing now")}
          </Button>
        </Link>
      </Flex>
      <Showcase />
    </>
  )
}
