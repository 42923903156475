import { Box } from "components/Layout.component"
import { Bold } from "components/legacy/typography"
import React from "react"
import styled from "styled-components"

const Background = styled(Box)<any>`
  background: rgba(87, 194, 21, 1);
  position: absolute;
  width: 100%;
  opacity: 0.1;
  border-radius: ${(props) => (props.percent ? "90" : "0")}px;
`

const Progress = styled(Box)<any>`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%),
    #0055ff;
  border-radius: 90px;
  position: absolute;
  width: calc(${(props) => props.percent.toString()}% + 5px);
  z-index: 10;
  left: -5px;
`

const ProgressAura = styled(Box)<any>`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%),
    #0055ff;
  opacity: 0.5;
  filter: blur(24px);
  border-radius: 90px;
  position: absolute;
  width: calc(${(props) => props.percent.toString()}% + 5px);
  z-index: 10;
  left: -5px;
  top: 5px;
`

const Container = styled(Box)`
  position: relative;
`

const PercentNumber = styled(Box)<any>`
  position: absolute;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  z-index: 100;
  left: calc(${(props) => props.percent.toString()}% + 10px);
`

const PercentNumberText = styled(Bold)``

interface IProgressBarProps {
  perMilleComplete: number
  stock?: { numSold: number; totalStock: number }
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ perMilleComplete, stock }) => {
  const percentComplete = perMilleComplete / 10
  return (
    <Container mb={{ _: 0, tablet: 4 }}>
      {percentComplete ? (
        <>
          {stock && (
            <>
              <PercentNumber percent={1} height={{ _: "32px", tablet: "50px" }}>
                <Bold>{stock.numSold}</Bold>
              </PercentNumber>
              <PercentNumber percent={91} height={{ _: "32px", tablet: "50px" }}>
                <Bold>{stock.totalStock}</Bold>
              </PercentNumber>
            </>
          )}
          <Progress percent={percentComplete} height={{ _: "32px", tablet: "50px" }} />
          <ProgressAura percent={percentComplete} height={{ _: "32px", tablet: "50px" }} />
          {!stock && (
            <PercentNumber percent={percentComplete} height={{ _: "32px", tablet: "50px" }}>
              <Box mt={{ _: -3, tablet: 0 }}>
                <PercentNumberText>{percentComplete.toFixed(0)}%</PercentNumberText>
              </Box>
            </PercentNumber>
          )}
        </>
      ) : null}
      <Background percent={percentComplete} height={{ _: "32px", tablet: "50px" }} />
    </Container>
  )
}
