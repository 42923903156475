import "swiper/swiper.scss" // core Swiper
import "swiper/modules/pagination/pagination.scss" // Pagination moduleuired modules
import { Flex, Box } from "components/Layout.component"
import { Text, Title } from "components/Typography.component"
import React from "react"
import { useTranslation } from "react-i18next"
import { BsArrowRight } from "react-icons/bs"
import { Link } from "react-router-dom"
import { PAGE_URLS } from "routes"
import styled from "styled-components"
import SwiperCore, { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import { theme } from "theme"

import { useShowcases } from "../queries"

import { ShowcaseCard } from "./ShowcaseCard.component"

// install Swiper modules
SwiperCore.use([Pagination])

const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullet {
    display: none;
  }
  @media screen and ${theme.mediaQueries.xs} {
    overflow: hidden;
    height: 360px;
  }
`

export const Showcase: React.FC = () => {
  const { data: showcases } = useShowcases()
  const { t } = useTranslation()

  if (!showcases) {
    return null
  }

  return (
    <Box mt={{ _: 7 }}>
      <Text textAlign={{ _: "center", md: "left" }}>{t("Showcase")}</Text>
      <Box mt={{ _: 1 }}>
        <Title variant="h2" textAlign={{ _: "center", md: "left" }}>
          {t("Past investment performance")}
        </Title>
      </Box>
      <Box mt={{ _: 6, md: 8 }}>
        <Flex flexDirection={{ _: "row", md: "row" }}>
          <StyledSwiper
            grabCursor
            slidesPerView={"auto"}
            spaceBetween={30}
            pagination={{ clickable: true }}
          >
            {showcases.map((projInfo) => (
              <SwiperSlide key={projInfo.id} style={{ width: "280px", overflow: "visible" }}>
                <ShowcaseCard showcase={projInfo} />
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </Flex>
        <Box>
          <Link to={PAGE_URLS.PRIVATE_DEALS}>
            <Flex alignItems="center" justifyContent="flex-end" mt={{ _: 5, xs: 0 }}>
              <Text
                textAlign="right"
                fontSize="12px"
                lineHeight="120%"
                opacity="1"
                fontWeight="bold"
                color="#ffffff"
              >
                {t("ALL DEALS")}
              </Text>
              <Box ml={2}>
                <BsArrowRight color="#ffffff" />
              </Box>
            </Flex>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
