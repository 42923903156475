import { DistributionChain, isNonEvmDistributionChain } from "constants/distributionChains"
import { ENTITIES, FEATURES, PROVIDERS } from "constants/queryKeys"
import { BACKEND_URLS } from "constants/urls"

import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import type { IAdditionalWallet } from "features/wallet/types"
import { useQuery, UseQueryResult } from "react-query"

import { IBackendNotification, KycStatus, IBlacklists } from "./types"

interface IBackendUserInfoResponse {
  notifications: IBackendNotification[]
  kycStatus: KycStatus
  blacklists: IBlacklists
  additionalWallets: IAdditionalWallet[]
}

const notificationKeys = {
  all: (account?: string | null) =>
    [{ feature: FEATURES.NOTIFICATION, provider: PROVIDERS.BACKEND, account }] as const,
  list: (account?: string | null) =>
    [{ ...notificationKeys.all(account)[0], entity: ENTITIES.LIST }] as const,
}

const getUserInfo = async (walletAddress?: string | null): Promise<IBackendUserInfoResponse> => {
  // should never occur as we are using the 'enabled' key on useQuery below but
  // added for extra safety checks
  if (!walletAddress) {
    new Error("invalid wallet address for get user info query")
  }

  try {
    const { data } = await axios.get<IBackendUserInfoResponse>(
      `${BACKEND_URLS.WALLET}/${walletAddress}`
    )
    return data
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.detail)
    } else if (error.request) {
      throw new Error("Request sent but server did not respond")
    } else {
      throw new Error("Unknown getting user info")
    }
  }
}

interface IUseGetUserInfo {
  <T = IBackendUserInfoResponse>(select?: (data: IBackendUserInfoResponse) => T): UseQueryResult<T>
}

export const useGetUserInfo: IUseGetUserInfo = (
  select?: (data: IBackendUserInfoResponse) => any
) => {
  const { account } = useWeb3React()
  return useQuery(
    notificationKeys.list(account),
    () => getUserInfo(account),
    // we dont expect notifications to change that often so we set a long stale
    // time. Reduce this in future if we want faster notification updates
    {
      enabled: Boolean(account),
      staleTime: 60_000,
      select,
    }
  )
}

export const useGetNotifications = () => {
  return useGetUserInfo((data: IBackendUserInfoResponse) => data.notifications)
}

export const useKycStatus = () => useGetUserInfo((data: IBackendUserInfoResponse) => data.kycStatus)

export const useTokenDistributorBlacklist = () =>
  useGetUserInfo((data: IBackendUserInfoResponse) => data.blacklists.tokenDistributorBlacklist)

export const useMustProvideAdditionalWallet = (chain?: DistributionChain) =>
  useGetUserInfo((data: IBackendUserInfoResponse) => {
    if (!isNonEvmDistributionChain(chain)) {
      return false
    }

    return !data.additionalWallets.some((item) => item.chain === chain)
  })
