import { BUSD_ADDRESS } from "constants/addresses"

import { useWeb3React } from "@web3-react/core"
import { BigNumber } from "ethers"
import { erc20Keys } from "helpers/tokenQueries"
import { useSnackbar } from "notistack"
import { useMutation, useQueryClient } from "react-query"
import invariant from "tiny-invariant"

import { useContractFromFactory, dealContractKeys } from "./contractQueries"
import { IBackendDealInfo } from "./types"

export const useContribute = ({
  contractAddress,
  contractFactoryAddress,
  name,
}: IBackendDealInfo) => {
  const { account } = useWeb3React()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const service = useContractFromFactory(contractAddress, contractFactoryAddress)

  return useMutation(
    async (amount: BigNumber) => {
      // we check for account here to ensure the contract passed to service is
      // a signer not just a provider
      invariant(account, "Account not given")
      invariant(service, "Service not given")

      const receipt = await service.contribute(amount)
      if (receipt.status !== 1) {
        throw new Error("Transaction failed")
      }

      return amount
    },
    {
      onSuccess: (amount: BigNumber) => {
        enqueueSnackbar(`Contributed $${amount.toFixed(0)} to ${name}`, { variant: "success" })
        // Note: we used to optimistically update here but we've removed it
        // because it caused the UI to flicker and people got annoyed

        // refetch user deal info
        queryClient.invalidateQueries(
          dealContractKeys.detail.all({ contractAddress, contractFactoryAddress })
        )
        // refetch BUSD balance
        queryClient.invalidateQueries(erc20Keys.detail.all(BUSD_ADDRESS))
        // refetch total raised
        queryClient.invalidateQueries(
          dealContractKeys.detail.totalRaised({ contractAddress, contractFactoryAddress })
        )
      },
      onError: (error: any) => {
        const message = error.data?.message
        // the default error message is not very helpful so we replace it
        if (message === "execution reverted: Target hit!") {
          enqueueSnackbar(`Not enough deal size left`, { variant: "error" })
          return
        }
        // any other errors
        enqueueSnackbar(error?.data?.message || error.message || error, {
          variant: "error",
        })
      },
    }
  )
}
