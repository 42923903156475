import { useWeb3React } from "@web3-react/core"
import { Flex } from "components/Layout.component"
import { Text } from "components/Typography.component"
import { Panel } from "components/legacy/layout"
import { Paragraph, Bold, H3 } from "components/legacy/typography"
import { Spinner } from "components/loading/Spinner.component"
import React from "react"
import { MdOfflineBolt } from "react-icons/md"
import styled from "styled-components"

import { usePersonalMax, useContribution } from "../contractQueries"
import { IBackendDealInfo } from "../types"

const Icon = styled(MdOfflineBolt)`
  color: #ffffff;
  width: 24px;
  height: 24px;
`
const TokenPriceContainer = styled(Flex)`
  margin-top: 18px;
  flex-direction: column;
`

const TokenPriceHeader = styled(Flex)`
  align-items: center;
`

interface IContributionPanelProps {
  deal: IBackendDealInfo
}
interface ILineItemProps {
  title: string
  content: string
}

const LineItem: React.FC<ILineItemProps> = ({ content, title }) => (
  <TokenPriceContainer>
    <TokenPriceHeader>
      <H3>{title.toUpperCase()}</H3>
    </TokenPriceHeader>
    <Paragraph>
      <Bold>{content}</Bold>
    </Paragraph>
  </TokenPriceContainer>
)

const Contribution: React.FC<IContributionPanelProps> = ({ deal }) => {
  const { data: contribution, isLoading } = useContribution(deal)

  if (isLoading) {
    return <Spinner />
  }

  if (!contribution) {
    return null
  }

  return <LineItem content={contribution.toFixed(0)} title="your contribution" />
}

const PersonalMax: React.FC<IContributionPanelProps> = ({ deal }) => {
  const { data: personalMax, isLoading } = usePersonalMax(deal)

  if (isLoading) {
    return <Spinner />
  }

  if (!personalMax) {
    return null
  }

  return <LineItem content={personalMax.toFixed(0)} title="personal max" />
}

export const ContributionPanel: React.FC<IContributionPanelProps> = ({ deal }) => {
  const { account } = useWeb3React()

  if (!account) {
    return (
      <Panel icon={<Icon />} title="Your USDT">
        <Text>Connect a wallet to see contribution info</Text>
      </Panel>
    )
  }

  return (
    <Panel icon={<Icon />} title="Your USDT">
      <Flex flexDirection="column">
        <Contribution deal={deal} />
        <PersonalMax deal={deal} />
      </Flex>
    </Panel>
  )
}
