import {
  WHITELIST_ALLOCATION_FACTORY_CONTRACT_ADDRESS,
  NO_WHITELIST_ALLOCATION_FACTORY_CONTRACT_ADDRESS,
  V3_GRACE_PERIOD_ALLOCATION_FACTORY_CONTRACT_ADDRESS,
  RBAC_ALLOCATION_FACTORY_CONTRACT_ADDRESS,
} from "constants/addresses"

import invariant from "tiny-invariant"

import {
  V0AllocationService,
  V1AllocationService,
  V2AllocationService,
  v0Abi,
  v1Abi,
  v2Abi,
} from "./services"
import { IAllocationConstructor } from "./types"

type AllocationServiceItem = {
  service: IAllocationConstructor
  /* human readable abi */
  abi: string[]
}

export const CONTRACT_FACTORY_ABI_MAP: Record<string, AllocationServiceItem> = {
  [WHITELIST_ALLOCATION_FACTORY_CONTRACT_ADDRESS.toLowerCase()]: {
    abi: v0Abi,
    service: V0AllocationService,
  },
  [NO_WHITELIST_ALLOCATION_FACTORY_CONTRACT_ADDRESS.toLowerCase()]: {
    abi: v1Abi,
    service: V1AllocationService,
  },
  [V3_GRACE_PERIOD_ALLOCATION_FACTORY_CONTRACT_ADDRESS.toLowerCase()]: {
    abi: v1Abi,
    service: V1AllocationService,
  },
  [RBAC_ALLOCATION_FACTORY_CONTRACT_ADDRESS.toLowerCase()]: {
    abi: v2Abi,
    service: V2AllocationService,
  },
}

const KNOWN_DEAL_CONTRACT_FACTORIES = Object.keys(CONTRACT_FACTORY_ABI_MAP)
const isKnownDealContractFactory = (address: string) =>
  KNOWN_DEAL_CONTRACT_FACTORIES.includes(address.toLowerCase())

export const getAbiAndServiceForContractFactory = (contractFactoryAddress: string) => {
  invariant(
    isKnownDealContractFactory(contractFactoryAddress),
    "Unknown deal contract factory address"
  )
  return CONTRACT_FACTORY_ABI_MAP[contractFactoryAddress.toLowerCase()]
}
