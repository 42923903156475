import { ENTITIES, FEATURES, PROVIDERS } from "constants/queryKeys"
import { BACKEND_URLS } from "constants/urls"

import axios from "axios"
import { useQuery } from "react-query"

import type { IShowcase } from "./types"

const showcaseKeys = {
  all: [{ feature: FEATURES.SHOWCASE, provider: PROVIDERS.BACKEND }] as const,
  list: [
    { feature: FEATURES.SHOWCASE, provider: PROVIDERS.BACKEND, entity: ENTITIES.LIST },
  ] as const,
}

const getAllShowcases = async () => {
  const { data } = await axios.get<IShowcase[]>(BACKEND_URLS.SHOWCASE)
  return data
}

export const useShowcases = () => {
  return useQuery<IShowcase[]>(showcaseKeys.list, getAllShowcases, {
    // these change very rarely
    staleTime: Infinity,
  })
}
