import { FundedDealsContainer } from "features/deal/components/FundedDealsContainer.component"
import { LiveDealsContainer } from "features/deal/components/LiveDealsContainer.component"
import { UpcomingDealsContainer } from "features/deal/components/UpcomingDealsContainer.component"
import React from "react"

import { IBackendDealResponse } from "../queries"

interface IAllDeals {
  deals: IBackendDealResponse
}

export const AllDeals: React.FC<IAllDeals> = ({ deals }) => {
  const { live, funded, upcoming } = deals

  return (
    <>
      <LiveDealsContainer deals={live} />

      <UpcomingDealsContainer deals={upcoming} />

      <FundedDealsContainer deals={funded} />
    </>
  )
}
