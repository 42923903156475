import React, { useEffect, useState } from "react"
import ReactGA from "react-ga"
import { useLocation } from "react-router-dom"

const initializeGA = () =>
  ReactGA.initialize("UA-212282196-1", { debug: process.env.NODE_ENV === "development" })

const logPageView = (page: string) => {
  ReactGA.set({ page: page })
  ReactGA.pageview(page)
  window.analytics.page()
}

// component which logs all page views using ReactGA
export const PageTracker: React.FC = ({ children }) => {
  const [GAInitialised, setGAInitialised] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (GAInitialised) {
      return
    }
    initializeGA()
    setGAInitialised(true)
  }, [GAInitialised])

  useEffect(() => {
    if (!GAInitialised) {
      console.error("trying to log page view before React GA is initialized!")
      return
    }
    logPageView(location.pathname)
  }, [location.pathname, GAInitialised])

  return <>{children}</>
}
