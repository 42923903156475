import { SUPPORTED_LANGUAGES } from "features/translation/config"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import ChainedBackend from "i18next-chained-backend"
import Backend from "i18next-locize-backend"
import resourcesToBackend from "i18next-resources-to-backend"
import { locizePlugin } from "locize"
import LastUsed from "locize-lastused"
import { initReactI18next } from "react-i18next"

const isProduction = process.env.NODE_ENV === "production"

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY, // The API key should only be used in development, not in production. You should not =expose your apps API key to production!!!
  referenceLng: "en",
  version: process.env.REACT_APP_LOCIZE_VERSION,
}

i18n
  .use(ChainedBackend)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: Boolean(isProduction),
    fallbackLng: "en",
    supportedLngs: Object.keys(SUPPORTED_LANGUAGES),
    nonExplicitSupportedLngs: true,
    saveMissing: !isProduction, // you should not use saveMissing in production
    // keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    locizeLastUsed: locizeOptions,
    backend: {
      backends: [
        resourcesToBackend((language, namespace, callback) => {
          import(`./locales/${language}/${namespace}.json`)
            .then(({ default: resources }) => {
              // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
              callback(null, resources)
            })
            .catch((error) => {
              callback(error, null)
            })
        }),
        Backend,
      ],
      backendOptions: [{}, locizeOptions],
    },
  })

if (!isProduction) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(LastUsed)
}

export default i18n
