import { distributionChainMap, isNonEvmDistributionChain } from "constants/distributionChains"
import type { NonEvmDistributionChain } from "constants/distributionChains"
import { BSCSCAN_URL } from "constants/urls"

import { useMediaQuery } from "@mantine/hooks"
import { useWeb3React } from "@web3-react/core"
import { Button } from "components/Button.component"
import { DealBadge } from "components/DealBadge.component"
import { Box, Flex } from "components/Layout.component"
import { Text } from "components/Typography.component"
import { TextInput } from "components/input/TextInput.component"
import { Panel } from "components/legacy/layout/Panel.component"
import { Bold, H3 } from "components/legacy/typography"
import { ethers, BigNumber } from "ethers"
import { ProjectLinks } from "features/deal/components/ProjectLinks.component"
import { useTotalRaised } from "features/deal/contractQueries"
import { isEnded } from "features/deal/selectors"
import { useMustProvideAdditionalWallet } from "features/notification/queries"
import { validateNonEvmAddress } from "features/wallet/addressValidation"
import { useSubmitAdditionalWallet } from "features/wallet/mutations"
import { Formik, ErrorMessage, Form, Field } from "formik"
import React from "react"
import { BiWallet } from "react-icons/bi"
import styled from "styled-components"
import { theme } from "theme"

import { selectDealLinks } from "../selectors"
import { IBackendDealInfo } from "../types"

import { About } from "./About.component"
import { ChainLogo } from "./ChainLogo.component"
import { ContributionPanel } from "./ContributionPanel.component"
import { ProgressBar } from "./ProgressBar.component"
import { RaiseInfo } from "./RaiseInfo.component"
import { TokenPrice } from "./TokenPrice.component"

const Container = styled(Box)`
  border-radius: 24px;
  color: #ffffff;
  width: 100%;
  max-width: 1226px;
`
const Header = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`
const HeaderRight = styled(Flex)`
  flex-direction: column;
`

const Live = styled(Box)`
  width: 85px;
  background: #1fcc92;
  box-shadow: 0px 4px 7px rgba(31, 204, 146, 0.4);
  border-radius: 6px;
  height: 32px;
  text-align: center;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Funded = styled(Box)`
  width: 120px;
  background: linear-gradient(135deg, #da5a37 0%, #daa438 100%);
  box-shadow: 0px 4px 7px rgba(218, 90, 55, 0.4);
  border-radius: 6px;
  height: 32px;
  text-align: center;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StatusAndContract = styled(Flex)``
const Underline = styled.div`
  border: 1px solid rgba(245, 158, 11, 1);
`

interface IViewContractProps {
  contractUrl: string
}

const ViewContract: React.FC<IViewContractProps> = ({ contractUrl }) => (
  <Flex flexDirection="column" justifyContent="center">
    <a href={contractUrl} rel="noreferrer" target="_blank">
      <Bold>View Contract</Bold>
      <Underline />
    </a>
  </Flex>
)

interface ILiveDealProps {
  deal: IBackendDealInfo
  contributeComponent?: React.ReactNode
}

const Separator = styled.div`
  min-height: 50px;
`

const BottomSection = styled(Flex)``

const getContractUrl = (contractAddress: string) => `${BSCSCAN_URL}/address/${contractAddress}`

function AdditionalWalletForm({ chain }: { chain: NonEvmDistributionChain }) {
  const mutation = useSubmitAdditionalWallet()
  const { account, library: provider } = useWeb3React()

  if (!account) {
    return null
  }

  return (
    <Formik
      initialValues={{ [chain.toUpperCase()]: "" }}
      onSubmit={async (values) => {
        mutation.error && mutation.reset()

        const rawMessage = [{ chain: chain.toUpperCase(), address: values[chain.toUpperCase()] }]

        const stringifiedMessage = JSON.stringify(rawMessage)
        const signer = provider.getSigner()
        const flatSig = await signer.signMessage(stringifiedMessage)
        const sig = ethers.utils.splitSignature(flatSig)
        mutation.mutate({
          unverifiedErc20Address: account,
          unverifiedMessage: stringifiedMessage,
          v: sig.v,
          r: sig.r,
          s: sig.s,
        })
      }}
    >
      {({ isValid, isSubmitting, dirty }) => (
        <Form>
          <Text variant="smallsubtitle">{distributionChainMap[chain]} address</Text>
          <Field
            as={TextInput}
            style={{ marginTop: 8 }}
            fullWidth
            name={`${chain.toUpperCase()}`}
            validate={validateNonEvmAddress(chain)}
          />
          <ErrorMessage name={`${chain.toUpperCase()}`}>
            {(msg) => <H3 color="#FF0266">{msg}</H3>}
          </ErrorMessage>
          <Box mt={2}>
            <Button fullWidth disabled={!dirty || isSubmitting || !isValid}>
              Confirm
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

function AdditionalWalletInfo({ deal, contributeComponent }: ILiveDealProps) {
  const { data: additionalWalletRequired, isLoading } = useMustProvideAdditionalWallet(deal.chain)

  if (isLoading) {
    return null
  }

  if (!deal.chain || !additionalWalletRequired || !isNonEvmDistributionChain(deal.chain)) {
    return <>{contributeComponent}</> || null
  }

  return (
    <Box mt={1}>
      <Panel
        transparent
        icon={<BiWallet />}
        title={
          <Text variant="subtitle" shade="white">
            Wallet details
          </Text>
        }
      >
        <Text>
          You must provide a {distributionChainMap[deal.chain]} wallet to participate in this deal.
        </Text>
        <Box mt={1}>
          <AdditionalWalletForm chain={deal.chain} />
        </Box>
      </Panel>
    </Box>
  )
}

export const LiveDeal: React.FC<ILiveDealProps> = ({ contributeComponent, deal }) => {
  const funded = isEnded(deal)
  const StatusComponent = funded ? Funded : Live
  const { data: contractTotalRaised } = useTotalRaised(deal)
  const isDesktop = useMediaQuery(theme.mediaQueries.sm)
  const contractUrl = getContractUrl(deal.contractAddress)

  // fallback to backend information if UI is fetching
  const totalRaised = contractTotalRaised || deal.totalRaised
  const links = selectDealLinks(deal)

  return (
    <Container
      backgroundColor="cardBackgroundPrimary"
      padding={{ tablet: "45px 62px", _: "24px 0px" }}
    >
      <Header>
        <Flex alignItems="center">
          <ChainLogo logoUrl={deal.logoUrl} chain={deal.chain} />
          <HeaderRight ml={{ tablet: 7, _: 2 }}>
            <RaiseInfo
              funded={funded}
              name={deal.name}
              dealSizeWei={deal.dealSize}
              raisedWei={totalRaised}
            />
            <StatusAndContract
              flexDirection={{ _: "column", tablet: "row" }}
              alignItems={{ _: "flex-start", tablet: "flex-end" }}
            >
              <StatusComponent>
                <Bold>{funded ? "FUNDED" : "LIVE"}</Bold>
              </StatusComponent>
              {!funded && (
                <Flex mt={{ tablet: 0, _: 1 }} ml={{ tablet: 4, _: 0 }}>
                  <ViewContract contractUrl={contractUrl} />
                </Flex>
              )}
            </StatusAndContract>
          </HeaderRight>
        </Flex>
        <Box alignSelf="flex-start">
          <DealBadge large={isDesktop} isIdo={deal.isIdo} />
        </Box>
      </Header>

      {!funded && (
        <>
          <ProgressBar
            perMilleComplete={totalRaised.mul(BigNumber.from("1000")).div(deal.dealSize).toNumber()}
          />

          <Separator />
        </>
      )}

      {deal.isGracePeriod && (
        <Flex>
          <H3 fontSize="1rem">
            <Bold fontSize="1rem">Grace period live</Bold>. Broker tier & above are allowed to
            contribute 25% of their max during the first five minutes (or until the deal is 50%
            full). Afterwards the deal is open to all tiers.
          </H3>
        </Flex>
      )}

      <BottomSection
        alignItems="stretch"
        mt={{ _: 0, tablet: 3 }}
        flexDirection={{ _: "column", tablet: "row" }}
        px={{ _: 3, tablet: 0 }}
      >
        <Box flex={{ tablet: "0 1 448px", _: "0 1 0" }}>
          <About
            about={
              <>
                {deal.about}
                {links && <ProjectLinks {...links} />}
              </>
            }
            moreInfoUrl={deal.discordInviteUrl}
          />
        </Box>

        <Flex
          ml={{ _: 0, tablet: 4 }}
          mt={{ _: 3, tablet: 0 }}
          flex={{ tablet: "1 1 278px", _: "1 1 0" }}
        >
          <ContributionPanel deal={deal} />
        </Flex>

        <Flex flex={{ sm: "0 1 320px" }} flexDirection="column">
          <Flex
            ml={{ tablet: 4, _: 0 }}
            height="100%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Flex justifyContent="space-between" mt={{ _: 3, tablet: 0 }}>
              <Panel>
                <TokenPrice
                  vestingSchedule={deal.vestingSchedule}
                  weightedAveragePrice={deal.weightedAveragePrice}
                />
              </Panel>
            </Flex>

            <AdditionalWalletInfo deal={deal} contributeComponent={contributeComponent} />
          </Flex>
        </Flex>
      </BottomSection>
    </Container>
  )
}
