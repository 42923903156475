import React from "react"
import styled from "styled-components"
import {
  variant,
  typography,
  opacity,
  OpacityProps,
  TypographyProps,
  color,
  ColorProps,
} from "styled-system"
import { theme } from "theme"

const headingBase = `
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${theme.colors.textPrimary};
`
interface HeadingProps extends TypographyProps, ColorProps, OpacityProps {}

const H1 = styled.h1<HeadingProps>`
  font-size: 48px;

  @media screen and ${theme.mediaQueries.xs} {
    font-size: 72px;
  }

  ${headingBase};
  ${typography};
  ${color};
  ${opacity};
`

const H2 = styled.h2<HeadingProps>`
  font-size: 32px;

  @media screen and ${theme.mediaQueries.xs} {
    font-size: 48px;
  }

  ${headingBase};
  ${typography};
  ${color};
  ${opacity};
`

const H3 = styled.h3<HeadingProps>`
  font-size: 32px;
  ${headingBase};
  ${typography};
  ${color};
  ${opacity};
`

const H4 = styled.h4<HeadingProps>`
  font-size: 24px;
  ${headingBase};
  ${typography};
  ${color};
  ${opacity};
`

const H5 = styled.h5<HeadingProps>`
  font-size: 20px;
  ${headingBase};
  ${typography};
  ${color};
  ${opacity};
`

const H6 = styled.h6<HeadingProps>`
  font-size: 16px;
  ${headingBase};
  ${typography};
  ${color};
  ${opacity};
`

type TextVariant = "small" | "subtitle" | "smallsubtitle" | "xssubtitle" | "bold"
type TextShade = "green" | "white" | "gold"
interface ITextProps extends TypographyProps, ColorProps {
  variant?: TextVariant
  shade?: TextShade
}

const TextBase = styled.div`
  opacity: 0.6;
  font-size: ${(props: any) => props.theme.fontSizes.body}px;
  color: ${(props: any) => props.theme.colors.textSecondary};
  line-height: 150%;
  ${typography};
  ${color};
`

export const Text = styled(TextBase)<ITextProps>(
  variant({
    prop: "variant",
    variants: {
      small: {
        fontSize: "small",
      },
      subtitle: {
        fontSize: "subtitle",
        fontWeight: "bold",
      },
      smallsubtitle: {
        fontSize: "small",
        fontWeight: "bold",
      },
      xssubtitle: {
        fontSize: "xs",
        fontWeight: "bold",
      },
      bold: {
        fontWeight: "bold",
      },
    },
  }),
  variant({
    prop: "shade",
    variants: {
      green: {
        color: "primary",
      },
      white: {
        color: "textPrimary",
        opacity: 1,
      },
      gold: {
        color: "#f59e0b",
        opacity: 1,
      },
    },
  })
)

type TitleVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

interface ITitleProps extends HeadingProps {
  variant: TitleVariant
}

export const Title: React.FC<ITitleProps> = ({ variant, children, ...rest }) => {
  switch (variant.toLowerCase()) {
    case "h1":
      return <H1 {...(rest as any)}>{children}</H1>
    case "h2":
      return <H2 {...(rest as any)}>{children}</H2>
    case "h3":
      return <H3 {...(rest as any)}>{children}</H3>
    case "h4":
      return <H4 {...(rest as any)}>{children}</H4>
    case "h5":
      return <H5 {...(rest as any)}>{children}</H5>
    case "h6":
      return <H6 {...(rest as any)}>{children}</H6>
    default:
      return <H6 {...(rest as any)} />
  }
}
