import type { DistributionChain } from "constants/distributionChains"

import { BigNumber, Contract, ethers } from "ethers"

export interface IAllocationConstructor {
  new (contract: Contract): AllocationService
}

export abstract class AllocationService {
  contract: Contract

  constructor(contract: Contract) {
    this.contract = contract
  }

  abstract getPersonalMax(address: string): Promise<BigNumber>
  abstract getContribution(address: string): Promise<BigNumber>
  abstract contribute(amount: BigNumber): Promise<ethers.providers.TransactionReceipt>
  abstract totalRaised(): Promise<BigNumber>
}

// this is locked to the structure the backend returns. Do not add keys here or
// to referenced types without first adding them to the backend
export interface IBackendDealInfo {
  id: string
  name: string
  about: string
  weightedAveragePrice: string
  isIdo: boolean
  vestingSchedule: string
  soldOutText?: string
  contractAddress: string
  contractFactoryAddress: string
  logoUrl?: string
  discordInviteUrl?: string
  dealSize: BigNumber
  minAllocation: BigNumber
  totalRaised: BigNumber
  started: boolean
  ended: boolean
  isUncapped: boolean
  isGracePeriod?: boolean
  desiredStartTime?: string
  // project links
  projectTelegramUrl?: string
  projectTwitterUrl?: string
  projectWebsiteUrl?: string
  projectWhitepaperUrl?: string
  projectDiscordUrl?: string
  chain?: DistributionChain
}
