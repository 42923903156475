import { Flex, Box } from "components/Layout.component"
import { Text } from "components/Typography.component"
import React from "react"
import { IoRocketOutline } from "react-icons/io5"

interface IBadgeProps {
  /* full opacity color */
  rgbaColor: string
  text: string
  Icon: React.ComponentType<any>
  large?: boolean
}

const Badge: React.FC<IBadgeProps> = ({ rgbaColor, text, Icon, large }) => {
  const backgroundColor = rgbaColor.replace(/[\d.]+\)$/g, "0.2)")
  return (
    <Flex
      py="4px"
      px={1}
      backgroundColor={backgroundColor}
      border={`1.5px solid ${rgbaColor}`}
      borderRadius="8px"
      alignItems="center"
    >
      <Text fontSize={large ? "small" : "xs"} opacity={1} color="white">
        {text}
      </Text>
      <Box ml={1}>
        <Icon color={rgbaColor} size={large ? 24 : 16} />
      </Box>
    </Flex>
  )
}

const Launchpad = ({ large }: Pick<IBadgeProps, "large">) => (
  <Badge large={large} text="Launchpad" rgbaColor="rgba(31, 204, 146, 1)" Icon={IoRocketOutline} />
)

const Private = ({ large }: Pick<IBadgeProps, "large">) => (
  <Badge large={large} text="Private" rgbaColor="rgba(245, 158, 11, 1)" Icon={IoRocketOutline} />
)

export function DealBadge({ isIdo, large }: { isIdo: boolean; large?: boolean }) {
  if (isIdo) {
    return <Launchpad large={large} />
  }
  return <Private large={large} />
}
