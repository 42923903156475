export function saveSlug(slug: string) {
  localStorage.setItem("slug", slug)
  localStorage.setItem("slugSavedAt", new Date().toISOString())
}

// function to retrieve slug if set
export function getSlug() {
  const slug = localStorage.getItem("slug")
  const savedAt = localStorage.getItem("slugSavedAt")
  if (!slug || !savedAt) {
    return undefined
  }

  // ensure slug was saved within the last 7 days
  const savedAtDate = new Date(savedAt)
  const now = new Date()
  const diff = now.getTime() - savedAtDate.getTime()
  const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24))
  if (diffDays > 7) {
    return undefined
  }

  return slug
}
