export const BSCSCAN_URL = "https://bscscan.com"

export const SAFELAUNCH_URLS = {
  DOCS: "https://docs.safelaunch.io",
  TG: "https://t.me/SafeLaunch_IO",
  TIERS: "https://docs.safelaunch.io/products/safealloc-private-deals/how-it-works-investors",
  SFEX_PANCAKESWAP:
    "https://pancakeswap.finance/swap?outputCurrency=0x5392Ff4a9BD006DC272C1855af6640e17Cc5ec0B",
  BNB_SFEX_LP_PANCAKESWAP:
    "https://exchange.pancakeswap.finance/#/add/BNB/0x5392Ff4a9BD006DC272C1855af6640e17Cc5ec0B",
  TOKEN_SWAP_MEDIUM_POST:
    "https://medium.com/safelaunch-io/safelaunch-to-take-immediate-steps-to-address-a-technical-issue-with-a-new-token-airdrop-and-5bc9749593ad",
  DISCORD: "https://discord.gg/xfZ3YSSkd9",
  TWITTER: "https://twitter.com/safelaunch1",
  IDO_FORM: "https://airtable.com/shrnD90BAUZ7ygO3i",
} as const

const PROD_BACKEND_URL = "https://api.safelaunch.io"
// should not be used in prod
const STAGING_BACKEND_URL = "https://staging.safelaunch.io"
const BACKEND_BASE_URL =
  process.env.NODE_ENV === "development" ? PROD_BACKEND_URL : PROD_BACKEND_URL

export const BACKEND_URLS = {
  ALL_DEALS: `${BACKEND_BASE_URL}/deal/deals`,
  ALL_SALES: `${BACKEND_BASE_URL}/sale/`,
  ALL_IDOS: `${BACKEND_BASE_URL}/deal/idos`,
  ALL_DEALS_AND_IDOS: `${BACKEND_BASE_URL}/deal/all`,
  NOTIFICATION: `${BACKEND_BASE_URL}/notification`,
  DEAL: `${BACKEND_BASE_URL}/deal`,
  WALLET: `${BACKEND_BASE_URL}/wallet`,
  KYC: `${BACKEND_BASE_URL}/kyc`,
  SHOWCASE: `${BACKEND_BASE_URL}/showcase`,
  VOTING: `${BACKEND_BASE_URL}/voting`,
  ADDITIONAL_WALLETS: `${BACKEND_BASE_URL}/wallet/additional-wallet`,
}
