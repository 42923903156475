import { Box } from "components/Layout.component"
import React from "react"
import { Outlet } from "react-router-dom"

import { Footer } from "./components/Footer.component"
import { Header } from "./components/Header.component"
import { SiteContainer } from "./components/SiteContainer.component"
import { useScrollOnRouteChange } from "./useScrollOnRouteChange.hook"

export const SiteLayout: React.FC = () => {
  useScrollOnRouteChange()
  return (
    <SiteContainer>
      <Box maxWidth="1280px" mx="auto" py={{ _: 8, md: 10 }} px={{ _: 2, md: 5 }} width="100%">
        <Header />

        <Box mt={{ _: 7, md: 7 }}>
          <Outlet />
        </Box>

        <Footer />
      </Box>
    </SiteContainer>
  )
}
