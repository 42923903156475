import { amountSfexToTierName } from "constants/tiers"

import { useStakedSfexBalance } from "./contractQueries"

export const useTier = () => {
  const { data: stakedSfexBalance } = useStakedSfexBalance()
  if (!stakedSfexBalance) {
    return null
  }

  return amountSfexToTierName(stakedSfexBalance)
}
