interface IBackendRequestAdditionalWallet {
  chain: string
}

// this needs to be kept up to date with the backend notification types enum
export enum IBackendNotificationType {
  RequestAdditionalWallet = "REQUEST_ADDITIONAL_WALLET",
}
// extend this when we add different notification types on the backend
export interface IBackendNotification {
  address: string
  type: IBackendNotificationType
  completed: boolean
  extraInfo?: IBackendRequestAdditionalWallet
}

// this needs to be synced from the backend enum of the same name
export enum KycStatus {
  NOT_STARTED = "NOT_STARTED",
  INCOMPLETE = "INCOMPLETE",
  WAITING = "WAITING",
  PASSED = "PASSED",
}

interface ITokenDistributorBlacklist {
  chainId: number
  distributionId: number
}

export interface IBlacklists {
  tokenDistributorBlacklist: ITokenDistributorBlacklist[]
}
