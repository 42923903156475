import type { SupportedChain } from "constants/chainIds"
import { isSupportedChain } from "constants/chainIds"
import { NETWORK_NAMES } from "constants/networkNames"

import { useWeb3React } from "@web3-react/core"
import { Box } from "components/Layout.component"
import { Text } from "components/Typography.component"
import React from "react"

interface IChainIdGuardProps {
  /* Children will only be rendered if wallet is connected to one of these
   * chain ids */
  supportedChains: SupportedChain[]
  /* Render children if the user does not have a wallet connected */
  allowWithNoWallet?: boolean
}

// Renders children only if user has a wallet connected and is on a supported
// chain
export const ChainIdGuard: React.FC<IChainIdGuardProps> = ({
  children,
  supportedChains,
  allowWithNoWallet,
}) => {
  const { chainId, account } = useWeb3React()
  if (!account) {
    if (allowWithNoWallet) {
      return <>{children}</>
    }
    return <Text>Please connect your wallet to continue</Text>
  }

  if (!isSupportedChain(chainId)) {
    return (
      <Box>
        <Text>
          You are on an unsupported network. Please connect to a supported network to continue.
        </Text>
      </Box>
    )
  }

  if (!supportedChains.includes(chainId)) {
    const networkNames = supportedChains.map((chainId) => NETWORK_NAMES[chainId])
    if (networkNames.length === 1) {
      return (
        <Text>Unsupported network. Please connect to {networkNames[0]} network to continue.</Text>
      )
    }
    if (networkNames.length === 2) {
      return (
        <Text>
          Unsupported network. Please connect to {networkNames[0]} or {networkNames[1]} network to
          continue.
        </Text>
      )
    }
    return (
      <Text>
        Unsupported network. Please connect to {networkNames.slice(0, -1).join(", ")} or{" "}
        {networkNames[1]} network to continue.
      </Text>
    )
  }

  return <>{children}</>
}
