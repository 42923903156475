import { Box, Flex, IBoxProps } from "components/Layout.component"
import { H2 } from "components/legacy/typography"
import React from "react"
import styled from "styled-components"

const PanelContainer = styled(Flex)`
  box-sizing: border-box;
  border-radius: 16px;
  padding: 18px;
  flex-direction: column;
  width: 100%;
`
const PanelHeader = styled(Flex)`
  align-items: center;
`

const PanelBody = styled(Box)``

interface IPanelProps extends IBoxProps {
  icon?: React.ReactNode
  title?: React.ReactNode | string
  children: React.ReactNode
  transparent?: boolean
}

export const Panel: React.FC<IPanelProps> = ({ children, icon, title, transparent, ...rest }) => {
  return (
    <PanelContainer background={transparent ? undefined : "#3d1743"} {...(rest as any)}>
      {title && (
        <PanelHeader>
          {icon ? <Box mr={2}>{icon}</Box> : null}

          <H2>{title}</H2>
        </PanelHeader>
      )}

      <PanelBody mt={{ _: title ? 1 : 0, tablet: title ? 2 : 0 }}>{children}</PanelBody>
    </PanelContainer>
  )
}
