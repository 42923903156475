export const SUPPORTED_LANGUAGES = {
  en: { nativeName: "English", name: "English" },
  tr: { name: "Turkish", nativeName: "Türkçe" },
  vi: { name: "Vietnamese", nativeName: "Tiếng Việt" },
  ru: { name: "Russian", nativeName: "русский язык" },
  zh: {
    name: "Chinese",
    nativeName: "中文",
  },
} as const
