import React from "react"
import styled from "styled-components"
import { position, PositionProps, variant, layout, LayoutProps } from "styled-system"

export type ButtonColor = "default" | "gold" | "purple"

export type ButtonSize = "xs" | "sm" | "lg" | "default" | "none"

export type ButtonVariant = "outlined" | "filled" //| "empty" | "link"
// export type ButtonVariant = "primary" | "secondary"
//
interface IBaseButtonProps extends PositionProps, LayoutProps {
  variant?: ButtonVariant
  size?: ButtonSize
  buttonColor?: ButtonColor
  disabled?: boolean
}

const BaseButton = styled("button")<IBaseButtonProps>(
  (props) => ({
    border: "0 solid",
    borderRadius: "0.625rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    lineHeight: "2rem",
    color: "#bcbcbc",
    textAlign: "center",
    cursor: props.disabled ? "not-allowed" : "hand",
  }),
  variant({
    variants: {
      outlined: {
        boxShadow: "0 0 0 0px #fff, 0 0 0 1px rgba(255,160,0,0.786), 0 0 transparent",
      },
      filled: {
        border: "0 solid",
      },
    },
  }),
  variant({
    prop: "size",
    variants: {
      default: {
        lineHeight: "2rem",
        fontSize: "1rem",
      },
      sm: {
        lineHeight: "1rem",
        fontSize: "0.75rem",
      },
      lg: {
        lineHeight: "2.5rem",
        fontSize: "1rem",
      },
    },
  }),
  variant({
    prop: "buttonColor",
    variants: {
      default: {
        bg: "backgroundSecondary",
        color: "textSecondary",
      },
      gold: {
        background: "linear-gradient(135deg, #da5a37 0%, #daa438 100%)",
        color: "#ffffff",
      },
      purple: {
        background: "#4d2154",
        color: "#ffffff",
      },
    },
  }),
  position,
  layout
)
export type ButtonProps = {
  color?: ButtonColor
  size?: ButtonSize
  variant?: ButtonVariant
  fullWidth?: boolean
} & {
  ref?: React.Ref<HTMLButtonElement>
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  IBaseButtonProps

export function Button({
  children,
  color = "default",
  size = "default",
  variant = "filled",
  fullWidth = false,
  ...rest
}: ButtonProps): JSX.Element {
  return (
    <BaseButton
      variant={variant}
      size={size}
      buttonColor={color}
      {...rest}
      width={fullWidth ? "100%" : "auto"}
    >
      {children}
    </BaseButton>
  )
}
