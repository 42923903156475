import { MantineProvider } from "@mantine/core"
import { Web3ReactProvider } from "@web3-react/core"
import { SnackbarProvider, useSnackbar } from "notistack"
import React from "react"
import { isMobile } from "react-device-detect"
import { QueryClient, QueryClientProvider, QueryCache } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { theme as reskinStyled } from "theme"
import { getLibrary } from "features/web3/utils"
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components"

interface AllTheProviders {
  children: React.ReactNode
}

const HEADER_NOT_FOUND_ERROR = "header not found"

const RQProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: any) => {
            const errorMessage = error.data?.message || error.message || error
            // These errors occur frequently, so we don't want to show them to
            // the user
            if (errorMessage === HEADER_NOT_FOUND_ERROR) {
              console.error("Multiple header not found messages suppressed")
              return
            }

            enqueueSnackbar(errorMessage, {
              variant: "error",
            })
          },
        }),
        defaultOptions: {
          queries: { notifyOnChangeProps: "tracked", retry: 5 },
        },
      }),
    [enqueueSnackbar]
  )

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

const reskinTheme = {
  primaryColor: "teal",
  colors: {
    grape: [
      "#faebfd",
      "#e7c6ec",
      "#d5a0dd",
      "#c47ad0",
      "#b456c3",
      "#893b96", // manually replaced (border)
      "#56255e", // manually replaced
      "#4d2154", // manually replaced
      "#3c1642", // manually replaced
      "#130416",
    ],
    teal: [
      "#ddfff3",
      "#b6f6e1",
      "#8cefce",
      "#61e7bb",
      "#38e1a8",
      "#1fcc92", // manually replaced
      "#15b680", // manually replaced
      "#066f4e",
      "#00432f",
      "#00180e",
    ],
  },
}

export const AllTheProviders: React.FC<AllTheProviders> = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      dense={isMobile}
      autoHideDuration={5000}
      maxSnack={3}
    >
      <RQProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <MantineProvider theme={reskinTheme as any}>
            <StyledComponentsThemeProvider theme={reskinStyled}>
              <ReactQueryDevtools initialIsOpen={false} />
              {children}
            </StyledComponentsThemeProvider>
          </MantineProvider>
        </Web3ReactProvider>
      </RQProvider>
    </SnackbarProvider>
  )
}
