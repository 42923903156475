import MINICHEF_ABI from "constants/abis/MissionControl.json"
import STAKED_SFEX_HELPER_ABI from "constants/abis/StakedSfexHelper.json"
import V1_SALE_ABI from "constants/abis/V1Sale.json"
import ERC20_ABI from "constants/abis/erc20.json"
import { MISSION_CONTROL_ADDRESS, STAKED_SFEX_HELPER_ADDRESS } from "constants/addresses"

import { getAddress } from "@ethersproject/address"
import { AddressZero } from "@ethersproject/constants"
import { Contract } from "@ethersproject/contracts"
import { Web3Provider, JsonRpcSigner } from "@ethersproject/providers"
import { useWeb3React } from "@web3-react/core"
import { useMemo } from "react"

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}
// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function useContract(address: string | undefined, ABI: any): Contract | null {
  const { library, account } = useWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library || !isAddress(address)) return null
    try {
      return getContract(address, ABI, library, account ? account : undefined)
    } catch (error) {
      console.error("Failed to get contract", error)
      throw error
    }
  }, [address, ABI, library, account])
}

export function useTokenContract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, ERC20_ABI)
}

export function useMissionControlContract(): Contract | null {
  return useContract(MISSION_CONTROL_ADDRESS, MINICHEF_ABI)
}

export function useStakedSfexContract(): Contract | null {
  return useContract(STAKED_SFEX_HELPER_ADDRESS, STAKED_SFEX_HELPER_ABI)
}

export function useV1SaleContract(contractAddress: string): Contract | null {
  return useContract(contractAddress, V1_SALE_ABI)
}
