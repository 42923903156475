import { useBooleanToggle, useClickOutside } from "@mantine/hooks"
import { Button } from "components/Button.component"
import { Flex, Box } from "components/Layout.component"
import { Text } from "components/Typography.component"
import React from "react"
import { useTranslation } from "react-i18next"
import { BsCaretDownFill } from "react-icons/bs"
import styled from "styled-components"

import { SUPPORTED_LANGUAGES } from "../config"

const ClickBox = styled(Flex)`
  cursor: pointer;
`

export function LanguageMenuDesktop({
  isMobile,
  onLanguageSelect,
}: {
  isMobile?: boolean
  onLanguageSelect?: () => void
}) {
  const { i18n } = useTranslation()
  const [opened, toggle] = useBooleanToggle(false)
  const ref = useClickOutside(() => toggle(false))

  return (
    <>
      <div ref={ref}>
        <ClickBox flexDirection="column" py={2} onClick={() => toggle()}>
          <Flex alignItems="center">
            <Button
              fullWidth
              color="dark"
              outlined
              rightIcon={<BsCaretDownFill color="#FFFFFF" opacity={1} size={12} />}
            >
              {i18n.language}
            </Button>
          </Flex>
          <Box display={opened ? "block" : "none"}>
            <Flex
              background="linear-gradient(0deg, rgba(66, 25, 72, 0.5), rgba(66, 25, 72, 0.5)), linear-gradient(138.82deg, #5A1963 -46.08%, #45174C 127.45%)"
              flexDirection="column"
              borderRadius="8px"
              boxShadow="0px 52px 36px rgba(50, 18, 55, 0.25)"
              p={2}
              border="1px solid rgba(137, 59, 150, 0.2)"
              position={isMobile ? "relative" : "absolute"}
              top={isMobile ? 0 : "144px"}
              zIndex={1000}
            >
              {Object.keys(SUPPORTED_LANGUAGES).map((lng, i) => (
                <Box
                  key={lng}
                  mt={i === 0 ? 0 : 2}
                  onClick={() => {
                    i18n.changeLanguage(lng)
                    onLanguageSelect && onLanguageSelect()
                  }}
                >
                  <Text opacity={1} variant="smallsubtitle">
                    {SUPPORTED_LANGUAGES[lng as keyof typeof SUPPORTED_LANGUAGES].nativeName}
                  </Text>
                </Box>
              ))}
            </Flex>
          </Box>
        </ClickBox>
      </div>
    </>
  )
}
