// transforms a URL into a format cloudflare image resizing can understand
export const convertImageToMaxSizes = ({
  url,
  width,
  height,
}: {
  url: string
  height: number
  width: number
}): string => {
  if (url.endsWith(".svg")) {
    return url
  }
  return `https://safelaunch.io/cdn-cgi/image/fit=contain,width=${width},height=${height}/${url}`
}
