import { BigNumber } from "ethers"

export const BigNumberMin = (b1: BigNumber, b2: BigNumber): BigNumber => {
  if (b1.gt(b2)) {
    return b2
  }
  return b1
}

export const BigNumberMax = (b1: BigNumber, b2: BigNumber): BigNumber => {
  if (b1.gt(b2)) {
    return b1
  }
  return b2
}
