import STAKED_SFEX_HELPER_ABI from "constants/abis/StakedSfexHelper.json"
import { STAKED_SFEX_HELPER_ADDRESS } from "constants/addresses"
import { ENTITIES, FEATURES, PROVIDERS } from "constants/queryKeys"

import { useWeb3React } from "@web3-react/core"
import { BigNumber } from "ethers"
import { getFastContract } from "helpers/contractHelpers"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"

export const stakedSfexKeys = {
  all: [{ feature: FEATURES.STAKED_SFEX, provider: PROVIDERS.WEB3 }] as const,
  detail: {
    all: (account?: string | null) =>
      [{ ...stakedSfexKeys.all[0], account, entity: ENTITIES.ITEM }] as const,
    staked: (account?: string | null) =>
      [{ ...stakedSfexKeys.detail.all(account)[0], variable: "staked" }] as const,
  },
}

// Thise uses a staked SFEX helper contract which queries the farming contracts
// sums the user's staked SFEX and staked SFEX-BNB LP (it applies a multiplier
// to SFEX-BNB LP) to calculate an SFEX equivalent amount. This amount is used
// to calculate which tier the user is in.
export const useStakedSfexBalance = () => {
  const { account } = useWeb3React()

  return useQuery<BigNumber>(
    stakedSfexKeys.detail.staked(account),
    async () => {
      invariant(account, "Account not given")
      const contract = getFastContract(STAKED_SFEX_HELPER_ADDRESS, STAKED_SFEX_HELPER_ABI)
      return contract.getStakedSfex(account)
    },
    {
      enabled: Boolean(account),
      // We expect this to only change when the user deposits into
      // our staking pools and we will invalidate this query there.
      staleTime: Infinity,
    }
  )
}
