import format from "date-fns/format"

// iso string to human readable string
export const isoToHuman = (isoDate: string) => {
  const date = new Date(isoDate)
  return format(date, "PP @  p")
}

// used for formatting block timestamps
export const secondsToHuman = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return format(date, "PP @  p")
}

// check if iso date string is in the future
export const isFuture = (isoDate: string) => {
  const date = new Date(isoDate)
  return date > new Date()
}
