import "./bootstrap"

import "./reset.scss"
import {
  BSC_MAINNET_ID,
  ETH_MAINNET_ID,
  POLYGON_MAINNET_ID,
  AVAX_MAINNET_ID,
} from "constants/chainIds"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { PageTracker } from "clients/analytics"
import { Spinner } from "components/loading/Spinner.component"
import { DealsLayout } from "features/deal/Deals.layout"
import AllDeals from "features/deal/Deals.page"
import Home from "features/home/Home.page"
import { Notification } from "features/notification/components/Notification.component"
import { LandingPage } from "features/referral/LandingPage.page"
import { SiteLayout } from "features/site/Site.layout"
import { ChainIdGuard } from "features/site/components/ChainIdGuard.component"
import useEagerConnect from "features/web3/useEagerConnect.hook"
import React from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { PAGE_URLS } from "routes"

const WalletDetailsPage = React.lazy(() => import("features/wallet/WalletDetails.page"))
const Sale = React.lazy(() => import("features/sale/Sales.page"))
const Farming = React.lazy(() => import("features/farm/Farming.page"))
const TermsAndConditions = React.lazy(() => import("pages/TermsPage"))
const Kyc = React.lazy(() => import("features/kyc/Kyc.page"))
const HowToJoin = React.lazy(() => import("features/howtojoin/HowToJoin.page"))
const FAQ = React.lazy(() => import("features/faq/FAQ.page"))
const TokenDistributor = React.lazy(
  () => import("features/tokendistributor/user/TokenDistributor.page")
)
const DistributeTokens = React.lazy(() => import("features/tokendistributor/admin/Distribute.page"))
const Voting = React.lazy(() => import("features/voting/Voting.page"))
const MyDeals = React.lazy(() => import("features/deal/MyDeals.page"))

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: "https://0db932a7de0f4967b3f119f5f1ee71bd@o261019.ingest.sentry.io/5902508",
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  })
}

function App() {
  useEagerConnect()

  return (
    <BrowserRouter>
      <PageTracker />
      <Notification />

      <Routes>
        {/* containts the header and footer of the site */}
        <Route path={PAGE_URLS.HOME} element={<SiteLayout />}>
          <Route index element={<Home />} />
          {/* Log incoming referrals */}
          <Route path="join/:slug" element={<LandingPage />} />

          {/* These redirects handle the old legacy routes */}
          <Route path="launch-pad" element={<Navigate to="/deals/launch-pad" />} />
          <Route path="safe-allocation" element={<Navigate to="/deals/private-deals" />} />
          <Route path="nft-pad" element={<Navigate to="/deals/nfts" />} />
          {/* End redirects */}

          <Route path="deals/" element={<DealsLayout />}>
            <Route
              path="all"
              element={
                <ChainIdGuard allowWithNoWallet supportedChains={[BSC_MAINNET_ID]}>
                  <AllDeals />
                </ChainIdGuard>
              }
            />

            <Route path="" element={<Navigate to="/deals/all" replace={true} />} />
            <Route path="private-deals" element={<Navigate to="/deals/all" replace={true} />} />
            <Route path="launch-pad" element={<Navigate to="/deals/all" replace={true} />} />

            <Route
              path={PAGE_URLS.SALES}
              element={
                <React.Suspense fallback={<Spinner />}>
                  <ChainIdGuard allowWithNoWallet supportedChains={[BSC_MAINNET_ID]}>
                    <Sale />
                  </ChainIdGuard>
                </React.Suspense>
              }
            />
          </Route>

          <Route
            path={PAGE_URLS.HOW_TO_JOIN}
            element={
              <React.Suspense fallback={<Spinner />}>
                <HowToJoin />
              </React.Suspense>
            }
          />

          <Route
            path={PAGE_URLS.STAKING}
            element={
              <React.Suspense fallback={<Spinner />}>
                <ChainIdGuard allowWithNoWallet supportedChains={[BSC_MAINNET_ID]}>
                  <Farming />
                </ChainIdGuard>
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.TOC}
            element={
              <React.Suspense fallback={<Spinner />}>
                <TermsAndConditions />
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.KYC}
            element={
              <React.Suspense fallback={<Spinner />}>
                <ChainIdGuard supportedChains={[BSC_MAINNET_ID]}>
                  <Kyc />
                </ChainIdGuard>
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.FAQ}
            element={
              <React.Suspense fallback={<Spinner />}>
                <FAQ />
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.VOTING}
            element={
              <React.Suspense fallback={<Spinner />}>
                <ChainIdGuard supportedChains={[BSC_MAINNET_ID]}>
                  <Voting />
                </ChainIdGuard>
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.MY_DEALS}
            element={
              <React.Suspense fallback={<Spinner />}>
                <ChainIdGuard supportedChains={[BSC_MAINNET_ID]}>
                  <MyDeals />
                </ChainIdGuard>
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.WALLET_DETAILS}
            element={
              <React.Suspense fallback={<Spinner />}>
                <ChainIdGuard supportedChains={[BSC_MAINNET_ID]}>
                  <WalletDetailsPage />
                </ChainIdGuard>
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.TOKEN_DISTRIBUTOR}
            element={
              <React.Suspense fallback={<Spinner />}>
                <ChainIdGuard
                  supportedChains={[
                    BSC_MAINNET_ID,
                    ETH_MAINNET_ID,
                    POLYGON_MAINNET_ID,
                    AVAX_MAINNET_ID,
                  ]}
                >
                  <TokenDistributor />
                </ChainIdGuard>
              </React.Suspense>
            }
          />
          <Route
            path={PAGE_URLS.DISTRIBUTE_TOKENS}
            element={
              <React.Suspense fallback={<Spinner />}>
                <ChainIdGuard supportedChains={[BSC_MAINNET_ID, ETH_MAINNET_ID]}>
                  <DistributeTokens />
                </ChainIdGuard>
              </React.Suspense>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default React.memo(App)
