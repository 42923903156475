import { useHover } from "@mantine/hooks"
import { Flex, Box } from "components/Layout.component"
import { Text, Title } from "components/Typography.component"
import { convertImageToMaxSizes } from "helpers/images"
import Numeral from "numeral"
import React from "react"
import { useTranslation, Trans } from "react-i18next"
import styled from "styled-components"

import type { IShowcase } from "../types"

const Logo = styled.img`
  max-width: 80px;
  max-height: 80px;
`
interface IShowcaseCardProps {
  showcase: IShowcase
}

export const ShowcaseCard: React.FC<IShowcaseCardProps> = ({ showcase }) => {
  const { hovered, ref } = useHover()
  const { t } = useTranslation()
  const profitMulti = Number(showcase.allTimeHigh) / Number(showcase.dealTokenPrice)

  return (
    <Flex
      p={3}
      ref={ref}
      flexDirection="column"
      bg={hovered ? "secondary" : "backgroundSecondary"}
      borderRadius={16}
      width={{ _: "280px" }}
      height="280px"
      boxShadow={hovered ? "0px 48px 48px rgba(0, 85, 255, 0.24)" : undefined}
    >
      <Flex alignItems="baseline" justifyContent="space-between">
        <Flex flexDirection="column" justifyContent="center" alignItems="space-between">
          <Text fontSize={1} fontWeight="bold">
            {t("Deal price")}
          </Text>
          <Text fontSize={2} shade={hovered ? "white" : undefined}>
            ${showcase.dealTokenPrice}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="flex-end">
          <Trans>
            <Text lineHeight="100%" fontSize={1} fontWeight="bold">
              Earned up to
            </Text>{" "}
            <Text shade={hovered ? "white" : "green"} variant="subtitle">
              {{ profitMultiplier: profitMulti.toFixed(0) }}x
            </Text>
          </Trans>
        </Flex>
      </Flex>

      <Flex
        mx="auto"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        height="100%"
      >
        <Flex alignItems="center" width="80px" height="80px">
          {showcase.logoUrl && (
            <Logo src={convertImageToMaxSizes({ url: showcase.logoUrl, width: 80, height: 80 })} />
          )}
        </Flex>
      </Flex>

      <Box mt={2}>
        <Title variant="h5" textAlign="center">
          {showcase.project}
        </Title>
        <Box mt={1}>
          <Text textAlign="center">
            <Trans>
              $1000 earned up to {{ profitInDollars: Numeral(profitMulti * 1000).format("$0,0") }}
            </Trans>
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}
