import { useMediaQuery } from "@mantine/hooks"
import { Button } from "components/Button.component"
import { Flex, Box } from "components/Layout.component"
import { Text, Title } from "components/Typography.component"
import { useOfferings } from "features/deal/queries"
import { isLive, isUpcoming, isEnded } from "features/deal/selectors"
import * as R from "ramda"
import React from "react"
import { useTranslation, Trans } from "react-i18next"
import styled from "styled-components"
import { theme } from "theme"

import { ProjectCard } from "./ProjectCard.component"

interface ITabItemProps {
  text: string
  selected: boolean
  setSelected: (x: any) => void
}

const TabItem: React.FC<ITabItemProps> = ({ text, selected, setSelected }) => {
  return (
    <Box
      borderBottom={selected ? "1px solid #FFFFFF" : "1px solid #4D2154"}
      py={2}
      onClick={setSelected}
      width={{ _: "60px", sm: "80px" }}
    >
      <Box my="auto">
        <Text textAlign="center" fontSize="10px">
          {text}
        </Text>
      </Box>
    </Box>
  )
}

const TabMenuContainer = styled(Flex)`
  cursor: pointer;
`

export const LiveAndUpcoming: React.FC = () => {
  const { data: deals } = useOfferings()
  const isDesktop = useMediaQuery(theme.mediaQueries.sm)
  const [tabIdx, setTabIdx] = React.useState(0)
  const [dealLimit, setDealLimit] = React.useState(20)
  const { t } = useTranslation()

  if (!deals) {
    return null
  }
  const tabFilters = [R.identity, isLive, isUpcoming, isEnded]

  const onTabClick = (idx: number) => () => setTabIdx(idx)
  const allDeals = deals.live.concat(deals.upcoming).concat(deals.funded)

  const allDealsFiltered = allDeals.filter(tabFilters[tabIdx]).slice(0, dealLimit)

  const showingAllDeals = allDeals.length <= dealLimit

  return (
    <Flex flexDirection="column">
      <Title variant="h1" textAlign="center">
        {t("Live and upcoming deals")}
      </Title>
      <TabMenuContainer alignItems="center" mx="auto" mt={{ _: 2, sm: 6 }}>
        <TabItem text={t("ALL")} selected={tabIdx === 0} setSelected={onTabClick(0)} />
        <TabItem text={t("LIVE")} selected={tabIdx === 1} setSelected={onTabClick(1)} />
        <TabItem text={t("UPCOMING")} selected={tabIdx === 2} setSelected={onTabClick(2)} />
      </TabMenuContainer>
      <Flex
        mx="auto"
        flexDirection={{ _: "column", sm: "row" }}
        flexWrap={{ sm: "wrap" }}
        mt={{ _: 3, sm: 6 }}
        justifyContent={{ xs: "space-around", xl: "flex-start" }}
      >
        {allDealsFiltered.map((projInfo) => (
          <Box key={projInfo.id} mt={{ _: 3 }} ml={{ sm: 2 }}>
            <Box opacity={isEnded(projInfo) ? 0.4 : 1}>
              <ProjectCard fullWidth={!isDesktop} project={projInfo} />
            </Box>
          </Box>
        ))}
      </Flex>
      <Box mx="auto" mt={2} width="50%" display={showingAllDeals ? "none" : undefined}>
        <Button fullWidth color="dark" outlined onClick={() => setDealLimit((limit) => limit + 16)}>
          {t("See More")}
        </Button>
      </Box>
    </Flex>
  )
}
