import { ENTITIES, FEATURES, PROVIDERS } from "constants/queryKeys"
import { BACKEND_URLS } from "constants/urls"

import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import * as R from "ramda"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"

import { IBackendDealInfo } from "./types"

export interface IBackendDealResponse {
  live: IBackendDealInfo[]
  funded: IBackendDealInfo[]
  upcoming: IBackendDealInfo[]
}

const dealKeys = {
  all: [{ feature: FEATURES.DEAL_BACKEND, provider: PROVIDERS.BACKEND }] as const,
  list: {
    all: [
      { feature: FEATURES.DEAL_BACKEND, provider: PROVIDERS.BACKEND, entity: ENTITIES.LIST },
    ] as const,
    myDeals: (account?: string | null) =>
      [
        {
          feature: FEATURES.DEAL_BACKEND,
          provider: PROVIDERS.BACKEND,
          entity: ENTITIES.LIST,
          account,
          type: "myDeals",
        },
      ] as const,
  },
  detail: (dealId: string) => [{ ...dealKeys.all[0], entity: ENTITIES.ITEM, dealId }],
}

const sortByStartTime = (a: IBackendDealInfo, b: IBackendDealInfo) => {
  if (!a.desiredStartTime) {
    return 1
  }
  if (!b.desiredStartTime) {
    return -1
  }
  const aStart = new Date(a.desiredStartTime)
  const bStart = new Date(b.desiredStartTime)
  return aStart.getTime() - bStart.getTime()
}

const sortByStartTimeReversed = (a: IBackendDealInfo, b: IBackendDealInfo) => {
  if (!a.desiredStartTime) {
    return -1
  }
  if (!b.desiredStartTime) {
    return 1
  }
  const aStart = new Date(a.desiredStartTime)
  const bStart = new Date(b.desiredStartTime)
  return bStart.getTime() - aStart.getTime()
}

const toBigNumber = (str: string) => str.toBigNumber(0)
export const addDealBigNumbers = (deal: IBackendDealInfo) =>
  R.evolve({
    dealSize: toBigNumber,
    totalRaised: toBigNumber,
    minAllocation: toBigNumber,
  })(deal as any)

const allDealsMap = (arr: IBackendDealInfo[]) => arr.map(addDealBigNumbers)
const allDealsMapWithSort = (arr: IBackendDealInfo[]) =>
  arr.map(addDealBigNumbers).sort(sortByStartTime as any)
const allDealsMapWithSortReversed = (arr: IBackendDealInfo[]) =>
  arr.map(addDealBigNumbers).sort(sortByStartTimeReversed as any)

const getDeals = async (url: string): Promise<IBackendDealResponse> => {
  const { data } = await axios.get(url)
  return R.evolve({
    live: allDealsMapWithSortReversed,
    funded: allDealsMap,
    upcoming: allDealsMapWithSort,
  })(data) as IBackendDealResponse
}

// A 'deal' is a single deal on the backend
// Until we implement pagination these are ALL the deals in the system
// (upcoming, live and funded)
export const useOfferings = () => {
  return useQuery<IBackendDealResponse, Error>(
    dealKeys.list.all,
    async () => {
      const all = await getDeals(BACKEND_URLS.ALL_DEALS_AND_IDOS)
      return all
    },
    {
      staleTime: 60_000,
    }
  )
}

const getDeal = async (dealId: string): Promise<IBackendDealInfo> => {
  const { data } = await axios.get(`${BACKEND_URLS.DEAL}/${dealId}`)

  return addDealBigNumbers(data as any) as IBackendDealInfo
}

const getMyDeals = async (walletAddress: string): Promise<IBackendDealInfo[]> => {
  const { data } = await axios.get(`${BACKEND_URLS.WALLET}/${walletAddress}/deals`)

  return allDealsMap(data as any) as IBackendDealInfo[]
}

// fetch a single deal
export const useDeal = (dealId: string) => {
  return useQuery<IBackendDealInfo, Error>(dealKeys.detail(dealId), () => getDeal(dealId))
}

export const useMyDeals = () => {
  const { account } = useWeb3React()

  return useQuery<IBackendDealInfo[], Error>(
    dealKeys.list.myDeals(account),
    async () => {
      invariant(account, "Wallet address not given")
      const deals = await getMyDeals(account)
      return deals
    },
    { enabled: Boolean(account), staleTime: 60_000 }
  )
}
