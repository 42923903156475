import { Spinner } from "components/loading/Spinner.component"
import { AllDeals } from "features/deal/components/AllDeals.component"
import { useOfferings } from "features/deal/queries"
import React from "react"

const DealsPage: React.FC = () => {
  const { data: deals } = useOfferings()
  if (!deals) {
    return <Spinner />
  }

  return <AllDeals deals={deals} />
}

export default DealsPage
