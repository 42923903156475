import React from "react"
import { Navigate, useParams } from "react-router-dom"
import { PAGE_URLS } from "routes"

import { saveSlug } from "./tracking"

export function LandingPage() {
  const { slug } = useParams()
  if (slug) {
    saveSlug(slug)
  }

  return <Navigate to={PAGE_URLS.HOME} />
}
