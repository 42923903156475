import { Box, Grid } from "components/Layout.component"
import { H1 } from "components/legacy/typography"
import { UpcomingDeal } from "features/deal/components/UpcomingDeal.component"
import { IBackendDealInfo } from "features/deal/types"
import React from "react"

interface INonLiveDealProps {
  deals: IBackendDealInfo[]
}

export const UpcomingDealsContainer: React.FC<INonLiveDealProps> = ({ deals }) => {
  if (!deals || deals.length == 0) {
    return null
  }

  return (
    <Box mt={5}>
      <H1 fontSize={{ _: "40px", tablet: "55px" }}>Coming Soon</H1>
      <Grid
        mt={6}
        alignItems="stretch"
        gridTemplateColumns={{ _: "1fr", tablet: "repeat(auto-fill, 600px)" }}
        gridRowGap={6}
        gridColumnGap={0}
      >
        {deals?.map((deal, i) => (
          <UpcomingDeal key={i} deal={deal} />
        ))}
      </Grid>
    </Box>
  )
}
