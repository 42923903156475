// All addresses are on BSC mainnet unless otherwise stated

// Farm/liquidity pool contract
export const MISSION_CONTROL_ADDRESS = "0xe62de0802CfAD644144f4392702E18f02C9a82F4"
// Used to determine how much SFEX equivalent the user has
export const STAKED_SFEX_HELPER_ADDRESS = "0xE15f24aDEEc422EAC821fF8163A5a62f9a6f51B0"

/* Allocation contracts */

/* Deprecated */
// We need to support these for backwards compatility for fetching & displaying the
// 'contribution amounts' for users that contributed to deals that used the
// legacy allocation contracts
export const WHITELIST_ALLOCATION_FACTORY_CONTRACT_ADDRESS =
  "0xe1747257489c15e83751eebc1adafee2b2ff8b9b"
export const NO_WHITELIST_ALLOCATION_FACTORY_CONTRACT_ADDRESS =
  "0x6e579436b61bac31c24cbafb1724f683b4a13631"
export const V3_GRACE_PERIOD_ALLOCATION_FACTORY_CONTRACT_ADDRESS =
  "0x043f543156406b5621f652134edf84956385dae4"
/* End Deprecated */

/* Current Allocation contract */
export const RBAC_ALLOCATION_FACTORY_CONTRACT_ADDRESS = "0x26af351fbb2bfb923301d499aa34005f2c64d7ba"

/* End Allocation contracts */

/* Token contracts */
// export const BUSD_ADDRESS = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" 
export const BUSD_ADDRESS = "0x55d398326f99059fF775485246999027B3197955" // <- USDT
export const SFEX_ADDRESS = "0x5392Ff4a9BD006DC272C1855af6640e17Cc5ec0B"
export const SFEX_BNB_LP_ADDRESS = "0x15f189c0c3f139794161a81e09c3cbf8d922dab9"
