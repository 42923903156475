import type { DistributionChain } from "constants/distributionChains"

import { useMediaQuery } from "@mantine/hooks"
import { DealBadge } from "components/DealBadge.component"
import { Box, Flex } from "components/Layout.component"
import { About } from "features/deal/components/About.component"
import { ChainLogo } from "features/deal/components/ChainLogo.component"
import { ProjectLinks, IProjectLinks } from "features/deal/components/ProjectLinks.component"
import React from "react"
import styled from "styled-components"
import { theme } from "theme"

const Container = styled(Flex)`
  border-radius: 24px;
  color: #ffffff;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
`
const DealHeader = styled(Flex)`
  align-items: center;
`

interface IDealCardProps {
  logoUrl?: string
  headerComponent: React.ReactNode
  about: string
  links?: IProjectLinks
  footerComponent?: React.ReactNode
  chain?: DistributionChain
  isIdo: boolean
}

export const DealCard: React.FC<IDealCardProps> = ({
  logoUrl,
  headerComponent,
  about,
  links,
  footerComponent,
  chain,
  isIdo,
}) => {
  const isDesktop = useMediaQuery(theme.mediaQueries.sm)
  return (
    <Container
      backgroundColor="cardBackgroundPrimary"
      width={{ tablet: "580px", _: "100%" }}
      px={{ tablet: 3, _: "24px" }}
      py={{ _: "24px" }}
      pb={{ tablet: 7, _: "24px" }}
    >
      <Box alignSelf="flex-end">
        <DealBadge large={isDesktop} isIdo={isIdo} />
      </Box>
      <DealHeader flexDirection={{ tablet: "row", _: "column" }}>
        <ChainLogo logoUrl={logoUrl} chain={chain} />

        <Box mt={{ _: 3, tablet: 0 }} ml={{ _: 2, tablet: 6 }}>
          {headerComponent}
        </Box>
      </DealHeader>

      <Box px={{ _: 0, tablet: 3 }}>
        <Box mt={{ _: 4, tablet: 7 }}>
          <About
            about={
              <>
                {about}
                {links && <ProjectLinks {...links} />}
              </>
            }
          />
        </Box>
      </Box>

      {footerComponent}
    </Container>
  )
}
