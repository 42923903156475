import React from "react"
import styled from "styled-components"

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

interface INumericalInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as"> {
  value: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: "right" | "left"
  fullWidth?: boolean
}

interface IRawInputProps {
  fullWidth?: boolean
}

const RawInput = styled.input<IRawInputProps>`
  position: relative;
  font-weight: 700;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-style: none;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5rem;
  padding: 0.75rem;
  background-color: rgba(182, 182, 182, 0.2);
  border-radius: 0.625rem;
  line-height: inherit;
  color: #ffffff;
  ${(props) => props.fullWidth && "width: 100%;"}
  &:focus {
      --ring-offset-shadow: 0 0 0 0px #fff;
      --ring-shadow: 0 0 0 1px rgba(255, 160, 0, 0.786);
      box-shadow: var(--ring-offset-shadow),var(--ring-shadow),0 0 transparent;
    }
  }
`

export const NumericalInput = React.memo(
  ({ value, onUserInput, placeholder, fullWidth, ...rest }: INumericalInputProps) => {
    const enforcer = (nextUserInput: string) => {
      if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
        onUserInput(nextUserInput)
      }
    }

    return (
      <RawInput
        {...rest}
        value={value}
        onChange={(event) => {
          // replace commas with periods, because uniswap exclusively uses period as the decimal separator
          enforcer(event.target.value.replace(/,/g, "."))
        }}
        // universal input options
        inputMode="decimal"
        title="Token Amount"
        autoComplete="off"
        autoCorrect="off"
        // text-specific options
        type="text"
        pattern="^[0-9]*[.,]?[0-9]*$"
        placeholder={placeholder || "0.0"}
        min={0}
        minLength={1}
        maxLength={79}
        spellCheck="false"
        fullWidth={fullWidth}
      />
    )
  }
)

NumericalInput.displayName = "NumericalInput"
