import { BigNumberish } from "ethers"
import { takeWhile } from "ramda"

export type TierName =
  | "Peddler"
  | "Hustler"
  | "Merchant"
  | "Wholesaler"
  | "Broker"
  | "Tycoon"
  | "Monopoly"

export interface ITier {
  name: TierName
  /* Min SFEX required to be in tier */
  amountSfex: number
  /* Min SFEX-BNB LP required to be in tier */
  amountSfexBnbLp: number
  /* Multiplier of base allocation used for determing contribution maxes */
  allocationMultiplier: number
  /* Whether tier can contribute during the grace period */
  gracePeriod?: boolean
  /* Whether tier is marked for potential removal later */
  isNoob?: boolean
}

export const TIERS: ITier[] = [
  { name: "Peddler", amountSfex: 300, amountSfexBnbLp: 5.5, allocationMultiplier: 1, isNoob: true },
  { name: "Hustler", amountSfex: 500, amountSfexBnbLp: 10, allocationMultiplier: 2, isNoob: true },
  { name: "Merchant", amountSfex: 1500, amountSfexBnbLp: 28, allocationMultiplier: 4 },
  { name: "Wholesaler", amountSfex: 3000, amountSfexBnbLp: 55, allocationMultiplier: 7 },
  {
    name: "Broker",
    amountSfex: 6000,
    amountSfexBnbLp: 110,
    allocationMultiplier: 10,
    gracePeriod: true,
  },
  {
    name: "Tycoon",
    amountSfex: 24000,
    amountSfexBnbLp: 440,
    allocationMultiplier: 18,
    gracePeriod: true,
  },
  {
    name: "Monopoly",
    amountSfex: 48000,
    amountSfexBnbLp: 875,
    allocationMultiplier: 30,
    gracePeriod: true,
  },
]

export const amountSfexToTierName = (amount: BigNumberish): string | null => {
  const tiersInOrder = TIERS.sort((a, b) => a.amountSfex - b.amountSfex)
  const tier = takeWhile((t) => t.amountSfex <= amount, tiersInOrder).pop()

  return tier ? tier.name : null
}
