import { FEATURES } from "constants/queryKeys"
import { BACKEND_URLS } from "constants/urls"

import axios from "axios"
import { useSnackbar } from "notistack"
import { useMutation, useQueryClient } from "react-query"
import type { ISignedMessage } from "types"

export const submitAdditionalWallet = async (signedMessage: ISignedMessage) => {
  try {
    const { data } = await axios.post(BACKEND_URLS.ADDITIONAL_WALLETS, signedMessage)
    return data
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.detail)
    } else if (error.request) {
      throw new Error("Request sent but server did not respond")
    } else {
      throw new Error("Unknown error submitting additional wallet address")
    }
  }
}

export const useSubmitAdditionalWallet = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  return useMutation(submitAdditionalWallet, {
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: "error" })
    },

    onSuccess: () => {
      queryClient.invalidateQueries([{ feature: FEATURES.ADDITIONAL_WALLETS }])
      queryClient.invalidateQueries([{ feature: FEATURES.NOTIFICATION }])
      enqueueSnackbar("Successfully submitted address", { variant: "success" })
    },
  })
}
