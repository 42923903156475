import { SAFELAUNCH_URLS } from "constants/urls"

import { Flex, Box } from "components/Layout.component"
import React from "react"
import { BsTelegram, BsTwitter, BsDiscord } from "react-icons/bs"

export const SocialLinks = () => (
  <Flex alignItems="center" justifyContent={{ _: "center", sm: "flex-start" }} mt={{ _: 2 }}>
    <a rel="noreferred noreferrer" target="_blank" href={SAFELAUNCH_URLS.DISCORD}>
      <BsDiscord size={32} color="#FFFFFF" />
    </a>
    <Box ml={{ _: 2 }}>
      <a rel="noreferred noreferrer" target="_blank" href={SAFELAUNCH_URLS.TG}>
        <BsTelegram size={32} color="#FFFFFF" />
      </a>
    </Box>
    <Box ml={{ _: 2 }}>
      <a rel="noreferred noreferrer" target="_blank" href={SAFELAUNCH_URLS.TWITTER}>
        <BsTwitter size={32} color="#FFFFFF" />
      </a>
    </Box>
  </Flex>
)
