import { BigNumber, Contract } from "ethers"

import { AllocationService } from "../types"

export const v1Abi = [
  "function contributedAmounts(address) external view returns (uint256)",
  "function getMaxContribution(address) external view returns (uint256)",
  "function contribute(uint256 value) external",
  "function started() view returns (bool)",
  "function endedAt() view returns (uint256)",
  "function totalRaised() view returns (uint256)",
  "function dealSize() view returns (uint256)",
  "function dealController() view returns (address)",
]

export class V1AllocationService extends AllocationService {
  constructor(contract: Contract) {
    super(contract)
  }

  getPersonalMax = async (account: string): Promise<BigNumber> => {
    return this.contract.getMaxContribution(account)
  }

  getContribution = async (account: string): Promise<BigNumber> => {
    return this.contract.contributedAmounts(account)
  }

  contribute = async (amount: BigNumber) => {
    const tx = await this.contract.contribute(amount)
    return tx.wait()
  }

  totalRaised = async () => {
    return this.contract.totalRaised()
  }
}
