import { Tooltip as MantineTooltip } from "@mantine/core"
import styled from "styled-components"

export const Tooltip = styled(MantineTooltip)`
  .mantine-tooltip-tooltip {
    background: rgba(77, 33, 84, 0.7);
    border-radius: 16px;
  }
  .mantine-tooltip-root {
    background: rgba(77, 33, 84, 0.7);
    border-radius: 16px;
  }
  .mantine-tooltip-wrapper {
    background: rgba(77, 33, 84, 0.7);
    border-radius: 16px;
  }
  .mantine-tooltip-body {
    background: rgba(77, 33, 84, 0.7);
    border-radius: 16px;
  }
  .mantine-tooltip-arrow {
    background: rgba(77, 33, 84, 0.7);
    border-radius: 16px;
  }
`
