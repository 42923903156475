import { Box } from "components/Layout.component"
import React from "react"

export const SiteContainer: React.FC = ({ children }) => {
  return (
    <Box width="100%" height="100%" minHeight="100vh" bg="backgroundPrimary">
      <Box>{children}</Box>
    </Box>
  )
}
