import { Box, Flex } from "components/Layout.component"
import { Paragraph, Bold, H1 } from "components/legacy/typography"
import { BigNumber } from "ethers"
import Numeral from "numeral"
import React from "react"
import styled from "styled-components"

const Container = styled(Flex)`
  flex-direction: column;
`

interface IRaiseInfoProps {
  name: string
  raisedWei: BigNumber
  dealSizeWei: BigNumber
  funded?: boolean
}

const formatDealNumbers = (value: BigNumber) => Numeral(value.toFixed(0)).format("0,0")

export const RaiseInfo: React.FC<IRaiseInfoProps> = ({ dealSizeWei, name, raisedWei, funded }) => {
  const dealSize = formatDealNumbers(dealSizeWei)
  const raised = formatDealNumbers(raisedWei)
  return (
    <Container>
      <H1>{name}</H1>
      {!funded && (
        <Box mt={2}>
          <Paragraph fontSize={{ tablet: "24px", _: "18px" }}>
            <Bold fontSize={{ tablet: "24px", _: "18px" }} fontWeight={600}>
              {raised} / {dealSize}
            </Bold>{" "}
            USDT raised
          </Paragraph>
        </Box>
      )}
    </Container>
  )
}
