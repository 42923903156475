import { Modal, Group } from "@mantine/core"
import { useWeb3React } from "@web3-react/core"
import { Button } from "components/Button.component"
import { Box } from "components/Layout.component"
import useAuth from "features/web3/useAuth.hook"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BiWallet } from "react-icons/bi"
import { BsCircleFill } from "react-icons/bs"

import { WalletCard } from "./WalletCard.component"
import { connectors } from "./config"
import { Config } from "./types"

const ConnectWalletButton: React.FC<{ openModal: () => void; logout: () => void }> = ({
  openModal,
  logout,
}) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()

  if (account) {
    const walletAddress = `${account.slice(0, 6)}...${account.slice(-4)}`
    return (
      <Button
        fullWidth
        color="dark"
        outlined
        onClick={logout}
        rightIcon={<BsCircleFill color="#22CC93" />}
      >
        {walletAddress}
      </Button>
    )
  }
  return (
    <Button fullWidth onClick={openModal} leftIcon={<BiWallet />}>
      {t("Connect Wallet")}
    </Button>
  )
}

// A modal component that allows the user to connect their wallet
export function ConnectWalletModal() {
  const [isOpen, setIsOpen] = useState(false)
  const { login, logout } = useAuth()

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        title="Choose Wallet"
        styles={{
          modal: {
            backgroundColor: "#421948",
            width: "100%",
            maxWidth: "400px",
          },
          title: { color: "white" },
        }}
      >
        {connectors.map((wallet: Config) => (
          <Box mt={{ _: 1, sm: 3 }} key={wallet.title}>
            <WalletCard walletConfig={wallet} login={login} closeModal={() => setIsOpen(false)} />
          </Box>
        ))}
      </Modal>

      <Group>
        <ConnectWalletButton logout={logout} openModal={() => setIsOpen(true)} />
      </Group>
    </>
  )
}
