import { pick } from "ramda"

import { IBackendDealInfo } from "./types"
type DealStartInfo = Pick<IBackendDealInfo, "started" | "ended">

export const isLive = ({ started, ended }: DealStartInfo) => started && !ended
export const isUpcoming = ({ started, ended }: DealStartInfo) => !started && !ended
export const isEnded = ({ ended }: DealStartInfo) => ended

export const selectDealLinks = pick([
  "projectTelegramUrl",
  "projectTwitterUrl",
  "projectWebsiteUrl",
  "projectWhitepaperUrl",
  "projectDiscordUrl",
])
