import { Flex, Box } from "components/Layout.component"
import { SocialLinks } from "components/SocialLinks.component"
import { Text } from "components/Typography.component"
import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink, Link } from "react-router-dom"
import { PAGE_URLS } from "routes"

import { MobileMenuItems } from "./Header.component"

const MenuLink: React.FC<{ item: any }> = ({ item, children }) => (
  <NavLink
    to={item.url}
    style={({ isActive }) => ({
      color: isActive ? "#FFFFFF" : "#E2BEE8",
      opacity: isActive ? 1 : 0.5,
      cursor: "pointer",
    })}
  >
    {children}
  </NavLink>
)

export const Footer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Box borderTop="1px solid #65376C" mt={{ _: 5 }}>
      <Flex justifyContent="space-between">
        <Box width={{ _: "100%", md: "50%" }}>
          <Box mt={{ _: 5 }}>
            <Text shade="white" fontWeight="bold">
              SafeLaunch
            </Text>
          </Box>
          <Box mt={{ _: 2 }}>
            <Text fontSize="small">
              The SafeLaunch team & advisors are from across the globe spanning six different
              countries. Our diverse background includes developers from projects such as Thorchain
              & IOTEX, former market makers & OTC traders, top scouts from other launchpads, Oxford
              University alumni, and successful entrepreneurs.
            </Text>
          </Box>
          <Flex justifyContent="space-between" flexDirection={{ _: "column" }}>
            <Box alignSelf="flex-start">
              <SocialLinks />
            </Box>
            <Box mt={{ _: 2, sm: 3 }}>
              <Text fontSize="small">© 2021 - All Rights Reserved</Text>
              <Flex alignItems="center" mt={{ _: 1 }}>
                <Link to={PAGE_URLS.TOC}>
                  <Text variant="xssubtitle">Privacy policy</Text>
                </Link>
                <Box
                  bg="textSecondary"
                  opacity={0.5}
                  borderRadius="32px"
                  width="4px"
                  height="4px"
                  mx={{ _: 2 }}
                />
                <Link to={PAGE_URLS.TOC}>
                  <Text variant="xssubtitle">Terms and conditions</Text>
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box display={{ _: "none", sm: "block" }}>
          <Flex mt={5} flexDirection="column" width="300px" height="250px" flexWrap="wrap" mr={5}>
            {MobileMenuItems.map((item: any) => {
              return (
                <MenuLink item={item} key={item.text}>
                  <Box key={item.text} p={2}>
                    <Text opacity="inherit" color="inherit" variant="smallsubtitle">
                      {t(item.text)}
                    </Text>
                  </Box>
                </MenuLink>
              )
            })}
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
