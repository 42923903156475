import { BUSD_ADDRESS } from "constants/addresses"
import { distributionChainMap } from "constants/distributionChains"

import { useWeb3React } from "@web3-react/core"
import { DealCard } from "components/DealCard.component"
import { Box } from "components/Layout.component"
import { Text } from "components/Typography.component"
import { Button as BaseButton, Button } from "components/legacy/Button.component"
import { H3 } from "components/legacy/typography"
import { Dots } from "components/loading/Dots.component"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { useMustProvideAdditionalWallet } from "features/notification/queries"
import { isoToHuman, isFuture } from "helpers/dates"
import { useApproval, useTokenAllowance } from "helpers/tokenQueries"
import React from "react"
import { Link } from "react-router-dom"
import { PAGE_URLS } from "routes"
import styled from "styled-components"

import { selectDealLinks } from "../selectors"
import { IBackendDealInfo } from "../types"

import { RaiseInfo } from "./RaiseInfo.component"
import { TokenPrice } from "./TokenPrice.component"

const Footer = styled.div`
  margin-top: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 80px;
  flex-direction: column;
`

const WhitelistLink = styled.a`
  width: 100%;
`

const DealContent = styled(Box)``

interface IUpcomingDealProps {
  deal: IBackendDealInfo
}

function AdditionalWalletInfo({ deal }: IUpcomingDealProps) {
  const { data: additionalWalletRequired } = useMustProvideAdditionalWallet(deal.chain)

  if (!additionalWalletRequired || !deal.chain) {
    return null
  }

  return (
    <Text variant="bold">
      You must provide a {distributionChainMap[deal.chain]} wallet to participate in this deal.
      Provide one{" "}
      <Link to={PAGE_URLS.WALLET_DETAILS}>
        <Text as="span" opacity={1} shade="green">
          here
        </Text>
      </Link>
      .
    </Text>
  )
}

export const UpcomingDeal: React.FC<IUpcomingDealProps> = ({ deal }) => {
  const { mutate: approve, isLoading: isApproving } = useApproval(
    BUSD_ADDRESS,
    deal.contractAddress
  )
  const { data: busdAllowance } = useTokenAllowance(BUSD_ADDRESS, deal.contractAddress)
  const { account } = useWeb3React()
  const { data: additionalWalletRequired } = useMustProvideAdditionalWallet(deal.chain)

  const links = selectDealLinks(deal)

  return (
    <DealCard
      logoUrl={deal.logoUrl}
      chain={deal.chain}
      about={deal.about}
      isIdo={deal.isIdo}
      links={links}
      headerComponent={
        <RaiseInfo name={deal.name} dealSizeWei={deal.dealSize} raisedWei={deal.totalRaised} />
      }
      footerComponent={
        <DealContent px={{ _: 0, tablet: 5 }}>
          <Box mt={5}>
            <TokenPrice
              vestingSchedule={deal.vestingSchedule}
              weightedAveragePrice={deal.weightedAveragePrice}
            />
          </Box>

          {!deal.started && deal.desiredStartTime && isFuture(deal.desiredStartTime) && (
            <Box mt={1}>
              <H3>START DATE</H3>
              <Text opacity={0.7} as="span">
                {isoToHuman(deal.desiredStartTime)}
              </Text>
              <Text variant="bold" shade="gold" as="span" style={{ marginLeft: 8 }}>
                {formatDistanceToNowStrict(new Date(deal.desiredStartTime))} from now
              </Text>
            </Box>
          )}

          <Box mt={1}>
            <AdditionalWalletInfo deal={deal} />
          </Box>

          <Footer>
            <WhitelistLink href={deal.discordInviteUrl} target="_blank" rel="noreferrer">
              <Button size="lg" color="gold" fullWidth>
                Deal Information
              </Button>
            </WhitelistLink>
            {account &&
              busdAllowance &&
              busdAllowance.lt("100000".toBigNumber(18)) &&
              !additionalWalletRequired && (
                <Box mt={2} width="100%">
                  <BaseButton
                    fullWidth
                    variant="outlined"
                    onClick={() => approve()}
                    disabled={isApproving}
                    buttonColor="purple"
                  >
                    {isApproving ? <Dots>Approving</Dots> : "Pre-Approve USDT"}
                  </BaseButton>
                </Box>
              )}
          </Footer>
        </DealContent>
      }
    />
  )
}
