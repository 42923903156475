import { Flex, Box } from "components/Layout.component"
import { Text } from "components/Typography.component"
import { Paragraph, Bold, H3 } from "components/legacy/typography"
import React from "react"
import { MdHelp } from "react-icons/md"
import styled from "styled-components"

const QuestionIcon = styled(MdHelp)`
  margin-left: 8px;
  color: #ffffff;
`

const TokenPriceContainer = styled(Flex)`
  flex-direction: column;
`

const TokenPriceHeader = styled(Flex)`
  align-items: center;
`

interface ITokenPriceProps {
  vestingSchedule: string
  weightedAveragePrice: string
}

export const TokenPrice: React.FC<ITokenPriceProps> = ({
  vestingSchedule,
  weightedAveragePrice,
}) => (
  <TokenPriceContainer>
    <TokenPriceHeader>
      <H3>TOKEN PRICE</H3>
    </TokenPriceHeader>
    <Paragraph>
      <Text opacity={0.7}>{weightedAveragePrice}</Text>
    </Paragraph>

    <Box mt={1}>
      <TokenPriceHeader>
        <H3>VESTING SCHEDULE</H3>
      </TokenPriceHeader>
      <Text opacity={0.7}>
        {vestingSchedule.split("\n").map((text) => (
          <p key={text}>{text}</p>
        ))}
      </Text>
    </Box>
  </TokenPriceContainer>
)
