import { Box, Flex } from "components/Layout.component"
import { Bold, Gold } from "components/legacy/typography"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const NotificationBannerContainer = styled(Flex)`
  padding: 6px 18px;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
`
const NotificationBannerText = styled(Bold)`
  font-weight: 550;
`

interface ILinkInfo {
  text: string
  url: string
  external?: boolean
}

interface INotificationBannerProps {
  text: string
  linkInfo?: ILinkInfo
}

export const NotificationBanner: React.FC<INotificationBannerProps> = ({ text, linkInfo }) => {
  const LinkComponent = linkInfo ? (
    linkInfo.external ? (
      <a href={linkInfo.url} rel="noreferrer" target="_blank">
        <Gold>{linkInfo.text}</Gold>
      </a>
    ) : (
      <Link to={linkInfo.url}>
        <Gold>{linkInfo.text}</Gold>
      </Link>
    )
  ) : null

  return (
    <NotificationBannerContainer backgroundColor="backgroundTertiary">
      <Box>
        <NotificationBannerText>{text}</NotificationBannerText>. {LinkComponent}
      </Box>
    </NotificationBannerContainer>
  )
}
