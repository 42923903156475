export const EVM_DISTRIBUTION_CHAINS = ["ETH", "BSC", "AVAX", "CELO", "POLYGON", "FTM"] as const
// tells compiler that EVM_CHAINS is a literal type rather than a string array
export type EVMDistributionChain = typeof EVM_DISTRIBUTION_CHAINS[number]

const EvmDistributionChainMap: Record<EVMDistributionChain, string> = {
  ETH: "Ethereum",
  BSC: "Binance Smart Chain",
  AVAX: "Avalanche",
  CELO: "Celo",
  POLYGON: "Polygon",
  FTM: "Fantom",
} as const

// Any additions to this MUST first be supported on the backend for additional
// wallet collection
export const NON_EVM_DISTRIBUTION_CHAINS = ["SOLANA", "ALGORAND"] as const
export type NonEvmDistributionChain = typeof NON_EVM_DISTRIBUTION_CHAINS[number]

const nonEvmDistributionChainMap: Record<NonEvmDistributionChain, string> = {
  SOLANA: "Solana",
  ALGORAND: "Algorand",
} as const

export const distributionChainMap = {
  ...EvmDistributionChainMap,
  ...nonEvmDistributionChainMap,
} as const

export type DistributionChain = NonEvmDistributionChain | EVMDistributionChain

export function isEvmDistributionChain(chain?: DistributionChain): chain is EVMDistributionChain {
  return EVM_DISTRIBUTION_CHAINS.includes(chain as EVMDistributionChain)
}

export function isNonEvmDistributionChain(
  chain?: DistributionChain
): chain is NonEvmDistributionChain {
  return NON_EVM_DISTRIBUTION_CHAINS.includes(chain as NonEvmDistributionChain)
}
