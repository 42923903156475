import { PROVIDERS } from "constants/queryKeys"

import { NoBscProviderError } from "@binance-chain/bsc-connector"
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector"
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from "@web3-react/walletconnect-connector"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { useQueryClient } from "react-query"

import { connectorLocalStorageKey } from "./config"
import { ConnectorNames } from "./types"
import { connectorsByName } from "./utils"
import { setupNetwork } from "./wallet"

const useAuth = () => {
  const { activate, deactivate, account } = useWeb3React()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const connector = connectorsByName[connectorID]
      if (connector) {
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork()
            if (hasSetup) {
              activate(connector)
            }
          } else {
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
              // enqueueSnackbar("Provider Error", "No provider was found")
              enqueueSnackbar(`No provider was found`, { variant: "error" })
            } else if (
              error instanceof UserRejectedRequestErrorInjected ||
              error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector
                walletConnector.walletConnectProvider = null
              }
              // toastError("Authorization Error", "Please authorize to access your account")
              enqueueSnackbar("Please authorize to access your account", { variant: "error" })
            } else {
              // toastError(error.name, error.message)
              enqueueSnackbar(error.message, { variant: "error" })
            }
          }
        })
        window.analytics.identify(account)
      } else {
        // toastError("Unable to find connector", "The connector config is wrong")
        enqueueSnackbar("The connector config is wrong", { variant: "error" })
      }
    },
    [activate, enqueueSnackbar, account]
  )

  const logout = useCallback(() => {
    // remove all cached data from our provider/wallet
    queryClient.removeQueries([{ provider: PROVIDERS.WEB3, account }])
    deactivate()
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem("walletconnect")) {
      connectorsByName.walletconnect.close()
      connectorsByName.walletconnect.walletConnectProvider = null
    }
    window.localStorage.removeItem(connectorLocalStorageKey)
  }, [deactivate, queryClient, account])

  return { login, logout }
}

export default useAuth
