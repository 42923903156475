import { Burger } from "@mantine/core"
import { useMediaQuery, useBooleanToggle, useClickOutside } from "@mantine/hooks"
import { useWeb3React } from "@web3-react/core"
import { Flex, Box } from "components/Layout.component"
import { Text, Title } from "components/Typography.component"
import { useTier } from "features/tiers/useTier.hook"
import { LanguageMenuDesktop } from "features/translation/components/LanguageMenuDesktop.component"
import { ConnectWalletModal } from "features/web3/ConnectWalletModal"
import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { BsCaretDownFill } from "react-icons/bs"
import { useLocation, NavLink, Link } from "react-router-dom"
import { PAGE_URLS } from "routes"
import styled from "styled-components"
import { theme } from "theme"

interface ISubmenu {
  component: React.ReactNode
  matchUrl: string
}

interface IMenuItem {
  text: string
  url: string
  external?: boolean
  submenu?: ISubmenu
  requiresWallet?: boolean
}

const DealsMenu = () => {
  const { t } = useTranslation()
  return (
    <Flex
      background="linear-gradient(0deg, rgba(66, 25, 72, 0.5), rgba(66, 25, 72, 0.5)), linear-gradient(138.82deg, #5A1963 -46.08%, #45174C 127.45%)"
      flexDirection="column"
      borderRadius="8px"
      boxShadow="0px 52px 36px rgba(50, 18, 55, 0.25)"
      p={3}
      border="1px solid rgba(137, 59, 150, 0.2)"
      position="absolute"
      top="128px"
      zIndex={1000}
    >
      <NavLink
        to={PAGE_URLS.ALL_DEALS}
        style={({ isActive }) => ({
          color: isActive ? "#FFFFFF" : "#E2BEE8",
          opacity: isActive ? 1 : 0.5,
          cursor: "pointer",
        })}
      >
        <Box>
          <Text opacity="inherit" color="inherit" variant="smallsubtitle">
            {t("All Deals")}
          </Text>
        </Box>
      </NavLink>

      <NavLink
        to={PAGE_URLS.VOTING}
        style={({ isActive }) => ({
          color: isActive ? "#FFFFFF" : "#E2BEE8",
          opacity: isActive ? 1 : 0.5,
          cursor: "pointer",
        })}
      >
        <Box mt={2}>
          <Text opacity="inherit" color="inherit" variant="smallsubtitle">
            {t("Voting")}
          </Text>
        </Box>
      </NavLink>

      <NavLink
        to={PAGE_URLS.SALES}
        style={({ isActive }) => ({
          color: isActive ? "#FFFFFF" : "#E2BEE8",
          opacity: isActive ? 1 : 0.5,
          cursor: "pointer",
        })}
      >
        <Box mt={2}>
          <Text opacity="inherit" color="inherit" variant="smallsubtitle">
            {t("NFTs")}
          </Text>
        </Box>
      </NavLink>

      <NavLink
        to={PAGE_URLS.MY_DEALS}
        style={({ isActive }) => ({
          color: isActive ? "#FFFFFF" : "#E2BEE8",
          opacity: isActive ? 1 : 0.5,
          cursor: "pointer",
        })}
      >
        <Box mt={2}>
          <Text opacity="inherit" color="inherit" variant="smallsubtitle">
            {t("My Deals")}
          </Text>
        </Box>
      </NavLink>

      <NavLink
        to={PAGE_URLS.WALLET_DETAILS}
        style={({ isActive }) => ({
          color: isActive ? "#FFFFFF" : "#E2BEE8",
          opacity: isActive ? 1 : 0.5,
          cursor: "pointer",
        })}
      >
        <Box mt={2}>
          <Text opacity="inherit" color="inherit" variant="smallsubtitle">
            {t("Wallet Details")}
          </Text>
        </Box>
      </NavLink>
    </Flex>
  )
}

export const DesktopMenuItems: IMenuItem[] = [
  { text: "Home", url: "/" },
  { text: "How to Join", url: PAGE_URLS.HOW_TO_JOIN },
  {
    text: "Deals",
    url: PAGE_URLS.DEAL_BASE,
    submenu: {
      component: <DealsMenu />,
      matchUrl: "/deals",
    },
  },
  { text: "Staking", url: PAGE_URLS.STAKING },
  { text: "FAQ", url: PAGE_URLS.FAQ },
  { text: "Claim Tokens", url: PAGE_URLS.TOKEN_DISTRIBUTOR, requiresWallet: true },
]

export const MobileMenuItems: IMenuItem[] = [
  { text: "Home", url: "/" },
  { text: "How to join", url: PAGE_URLS.HOW_TO_JOIN },
  { text: "All Deals", url: PAGE_URLS.ALL_DEALS },
  { text: "NFTs", url: PAGE_URLS.SALES },
  { text: "Staking", url: PAGE_URLS.STAKING },
  { text: "FAQ", url: PAGE_URLS.FAQ },
  { text: "Claim Tokens", url: PAGE_URLS.TOKEN_DISTRIBUTOR, requiresWallet: true },
  { text: "Voting", url: PAGE_URLS.VOTING, requiresWallet: true },
  { text: "My Deals", url: PAGE_URLS.MY_DEALS, requiresWallet: true },
]

// const KycComplete = () => <Badge text="KYC" rgbaColor="rgba(255, 88, 88, 1)" Icon={IoRocketOutline} />

// styled anchor tag which has a grey text color
const Anchor = styled.a`
  color: ${theme.colors.textPrimary};
  opacity: 0.5;
`

// component which renders either a NavLink or a Link depending on the external property of the menu item
const MenuLink: React.FC<{ item: IMenuItem }> = ({ children, item }) => {
  if (item.external) {
    return (
      <Anchor href={item.url} rel="noreferrer" target="_blank">
        {children}
      </Anchor>
    )
  }
  return (
    <NavLink
      to={item.url}
      style={({ isActive }) => ({
        color: isActive ? "#FFFFFF" : "#E2BEE8",
        opacity: isActive ? 1 : 0.5,
        cursor: "pointer",
      })}
    >
      {children}
    </NavLink>
  )
}

const MobileMenu: React.FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  return (
    <Flex
      zIndex={20}
      position="absolute"
      top={10}
      left={0}
      flexDirection="column"
      mt={2}
      px={3}
      width="100%"
      backgroundColor="backgroundPrimary"
      height="100vh"
    >
      {MobileMenuItems.map((item, i, a) => {
        if (item.requiresWallet && !account) {
          return null
        }

        return (
          <MenuLink key={item.text} item={item}>
            <Box mt={4} mb={i === a.length - 1 ? 2 : 0}>
              <Title color="inherit" opacity="inherit" variant="h4">
                {t(item.text)}
              </Title>
            </Box>
          </MenuLink>
        )
      })}
      <LanguageMenuDesktop isMobile={true} onLanguageSelect={closeMenu} />
    </Flex>
  )
}

const SafeLaunchLogo: React.FC = () => {
  // check to see if the screen is on a mobile device
  const isTablet = useMediaQuery(theme.mediaQueries.tablet)
  const isLargeDesktop = useMediaQuery(theme.mediaQueries.lg)
  return (
    <Link to={PAGE_URLS.HOME}>
      <Title variant={isLargeDesktop ? "h3" : isTablet ? "h6" : "h3"}>SafeLaunch</Title>
    </Link>
  )
}

const MobileHeader: React.FC = () => {
  const [opened, setOpened] = React.useState(false)
  const location = useLocation()
  React.useEffect(() => setOpened(false), [location, setOpened])
  const closeMenu = React.useCallback(() => setOpened(false), [setOpened])
  const tierName = useTier()
  const { t } = useTranslation()

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between">
        <SafeLaunchLogo />
        <Flex alignItems="center" onClick={() => setOpened((o) => !o)}>
          <Text variant="subtitle">{!opened ? "Menu" : "Close"}</Text>
          <Box ml={3}>
            <Burger opened={opened} color={theme.colors.textSecondary} />
          </Box>
        </Flex>
      </Flex>
      {opened && <MobileMenu closeMenu={closeMenu} />}
      <Box mt={2} display={tierName ? "block" : "none"}>
        <Flex justifyContent="center" alignItems="center">
          <Text variant="smallsubtitle" lineHeight="100%">
            {t("Current Tier:")}
          </Text>
          <Box ml={1}>
            <Text fontWeight="bold" fontSize="small" color="textPrimary" opacity={1}>
              {tierName}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box mt={2}>
        <ConnectWalletModal />
      </Box>
    </Box>
  )
}
const ClickBox = styled(Flex)`
  cursor: pointer;
`

const HeaderItemWithSubmenu: React.FC<{ item: IMenuItem }> = ({ item }) => {
  const [opened, toggle] = useBooleanToggle(false)
  const isLargeDesktop = useMediaQuery(theme.mediaQueries.lg)
  const isExtraLargeDesktop = useMediaQuery(theme.mediaQueries.xl)
  const location = useLocation()
  const ref = useClickOutside(() => toggle(false))
  const { t } = useTranslation()

  if (!item.submenu) {
    return null
  }

  const isRouteMatch = location.pathname.includes(item.submenu.matchUrl)

  return (
    <div ref={ref}>
      <ClickBox
        flexDirection="column"
        ml={isExtraLargeDesktop ? 6 : isLargeDesktop ? 5 : 4}
        key={item.text}
        py={2}
        onClick={() => toggle()}
      >
        <Flex alignItems="center">
          <Text
            variant="smallsubtitle"
            color={isRouteMatch ? "textPrimary" : "textSecondary"}
            opacity={isRouteMatch ? 1 : 0.6}
          >
            {t(item.text)}
          </Text>
          <Box ml={1}>
            <BsCaretDownFill
              color={isRouteMatch ? "#FFFFFF" : "#E2BEE8"}
              opacity={isRouteMatch ? 1 : 0.5}
              size={12}
            />
          </Box>
        </Flex>
        <Box display={opened ? "block" : "none"}>{item.submenu.component}</Box>
      </ClickBox>
    </div>
  )
}

const LargeHeader: React.FC = () => {
  const isLargeDesktop = useMediaQuery(theme.mediaQueries.lg)
  const isExtraLargeDesktop = useMediaQuery(theme.mediaQueries.xl)
  const tierName = useTier()
  const { account } = useWeb3React()
  const { t } = useTranslation()

  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center" justifyContent="flex-start">
        <SafeLaunchLogo />
        <Flex alignItems="center">
          {DesktopMenuItems.map((item) => {
            if (item.requiresWallet && !account) {
              return null
            }
            if (!item.submenu) {
              return (
                <MenuLink item={item} key={item.text}>
                  <Box ml={isExtraLargeDesktop ? 6 : isLargeDesktop ? 5 : 4} key={item.text} py={2}>
                    <Text opacity="inherit" color="inherit" variant="smallsubtitle">
                      {t(item.text)}
                    </Text>
                  </Box>
                </MenuLink>
              )
            }

            return <HeaderItemWithSubmenu item={item} key={item.text} />
          })}
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Box display={{ _: "none", lg: tierName ? "block" : "none" }}>
          <Flex flexDirection="column" mr={4} py={1}>
            <Flex flexDirection="column" pl={2} borderLeft="1px solid #65376C">
              <Text variant="smallsubtitle" lineHeight="100%">
                {t("Current Tier")}
              </Text>
              <Text fontWeight="bold" fontSize="small" color="textPrimary" opacity={1}>
                {tierName}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <ConnectWalletModal />
        <Box ml={2}>
          <LanguageMenuDesktop />
        </Box>
      </Flex>
    </Flex>
  )
}

export const Header: React.FC = () => {
  const isDesktop = useMediaQuery(theme.mediaQueries.sm)
  if (isDesktop) {
    return <LargeHeader />
  }
  return <MobileHeader />
}
