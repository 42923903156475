import { useStakedSfexBalance } from "features/tiers/contractQueries"
import { useTier } from "features/tiers/useTier.hook"
import { capitalise } from "helpers/text"
import * as React from "react"
import { PAGE_URLS } from "routes"

import { useGetNotifications, useKycStatus } from "../queries"
import { IBackendNotification, IBackendNotificationType, KycStatus } from "../types"

import { NotificationBanner } from "./NotificationBanner.component"

export interface INotificationData {
  notifications: IBackendNotification[]
}

const findActionableNotificationComponent = (notifications: IBackendNotification[]) => {
  const additionalWallet = notifications.find(
    (n) => n.type == IBackendNotificationType.RequestAdditionalWallet
  )
  if (additionalWallet) {
    if (!additionalWallet.extraInfo) {
      return null
    }
    return (
      <NotificationBanner
        text={`You need to provide a ${capitalise(
          additionalWallet.extraInfo.chain
        )} address for a deal you contributed to.`}
        linkInfo={{
          text: "Provide one here.",
          url: PAGE_URLS.WALLET_DETAILS,
        }}
      />
    )
  }
  return null
}

export const Notification: React.FC = () => {
  const { data: notifications } = useGetNotifications()
  const { data: kycStatus } = useKycStatus()
  const tierName = useTier()
  const { data: stakedSfexBalance } = useStakedSfexBalance()

  // show null instead of loading
  if (!notifications) {
    return null
  }

  const NotificationComponent = findActionableNotificationComponent(notifications)
  if (NotificationComponent) {
    return NotificationComponent
  }

  if (stakedSfexBalance && !stakedSfexBalance.isZero()) {
    if (kycStatus == KycStatus.WAITING) {
      return (
        <NotificationBanner text="Your KYC documents have been submitted and are awaiting validation" />
      )
    }
    if (kycStatus == KycStatus.PASSED) {
      return <NotificationBanner text="You have successfully passed KYC" />
    }
    return (
      <NotificationBanner
        text="To be eligible to participate in deals, KYC must be completed."
        linkInfo={{
          text: "Submit KYC",
          url: PAGE_URLS.KYC,
        }}
      />
    )
  }

  return null
}
