import { Paragraph, Bold, Gold } from "components/legacy/typography"
import React from "react"
import styled from "styled-components"

const TrustContainer = styled.div`
  background: #4d2154;
  border-radius: 12px;
  padding: 6px 18px;
`

const TrustText = styled(Bold)`
  font-weight: 500;
`

export const Trust: React.FC = () => (
  <TrustContainer>
    <Paragraph>
      <TrustText>These deals are built on trust.</TrustText>{" "}
      <a href="/terms-and-conditions" target="_blank">
        <Gold>See here for more info</Gold>
      </a>
    </Paragraph>
  </TrustContainer>
)
