export const PAGE_URLS = {
  HOME: "/",
  // deals
  DEAL_BASE: "/deals",
  ALL_DEALS: "/deals/all",
  LAUNCHPAD: "/deals/launch-pad",
  PRIVATE_DEALS: "/deals/private-deals",
  MY_DEALS: "/deals/my-deals",
  WALLET_DETAILS: "/deals/wallet-details",
  SALES: "/deals/nfts",
  // farming
  STAKING: "/staking",
  TOC: "/terms-and-conditions",
  KYC: "/kyc",
  // new routes
  DEALS: "/deals",
  HOW_TO_JOIN: "/how-to-join",
  FAQ: "/faq",
  TOKEN_DISTRIBUTOR: "/token-distributor",
  DISTRIBUTE_TOKENS: "/token-distributor/distribute",
  VOTING: "/vote",
}
