import { BigNumber, Contract } from "ethers"

import { AllocationService } from "../types"

export const v0Abi = [
  "function contributedAmounts(address) external view returns (uint256)",
  "function maxAllocations(address) external view returns (uint256)",
  "function whitelistedAddresses(address) external view returns (bool)",
  "function contribute(uint256 value) external",
  "function started() view returns (bool)",
  "function ended() view returns (bool)",
  "function totalRaised() view returns (uint256)",
  "function dealSize() view returns (uint256)",
  "function dealController() view returns (address)",
]

export class V0AllocationService extends AllocationService {
  constructor(contract: Contract) {
    super(contract)
  }

  getPersonalMax = async (account: string): Promise<BigNumber> => {
    return this.contract.maxAllocations(account)
  }

  getContribution = async (account: string): Promise<BigNumber> => {
    return this.contract.contributedAmounts(account)
  }

  contribute = async (amount: BigNumber) => {
    const tx = await this.contract.contribute(amount)
    return tx.wait()
  }

  totalRaised = async () => {
    return this.contract.totalRaised()
  }
}
