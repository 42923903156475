import { Pagination } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { Box, Flex } from "components/Layout.component"
import { Text } from "components/Typography.component"
import { H1 } from "components/legacy/typography"
import { LiveDeal } from "features/deal/components/LiveDeal.component"
import { IBackendDealInfo } from "features/deal/types"
import React from "react"
import { theme } from "theme"

interface IFundedDealsContainerProps {
  deals: IBackendDealInfo[]
  overrideTitle?: string
}

const NUM_DEALS_PER_PAGE = 5

export const FundedDealsContainer: React.FC<IFundedDealsContainerProps> = ({
  deals,
  overrideTitle,
}) => {
  const [activePage, setPage] = React.useState(1)
  const [paginationTouched, setPaginationTouched] = React.useState(false)
  const headerRef = React.useRef<null | HTMLDivElement>(null)
  const scrollToHeaderRef = () => {
    if (paginationTouched) {
      headerRef?.current?.scrollIntoView({ behavior: "smooth" })
    }
  }
  React.useEffect(scrollToHeaderRef, [activePage, paginationTouched])
  const isDesktop = useMediaQuery(theme.mediaQueries.xs)

  const numDeals = deals.length
  if (!deals) {
    return null
  }
  if (deals.length == 0) {
    return (
      <Box mt={5} mb={5} ref={headerRef}>
        <H1 fontSize={{ _: "40px", tablet: "55px" }}>{overrideTitle ? overrideTitle : "Funded"}</H1>
        <Box mt={3}>
          <Text>
            You have not yet contributed to any deals. Deals will show here once they have ended.
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box mt={5} mb={5} ref={headerRef}>
      <H1 fontSize={{ _: "40px", tablet: "55px" }}>{overrideTitle ? overrideTitle : "Funded"}</H1>
      {deals
        ?.slice(
          (activePage - 1) * NUM_DEALS_PER_PAGE,
          (activePage - 1) * NUM_DEALS_PER_PAGE + NUM_DEALS_PER_PAGE
        )
        .map((deal, i) => (
          <Box key={i} mt={5}>
            <LiveDeal key={deal.id} deal={deal} />
          </Box>
        ))}

      <Flex alignItems="center" justifyContent="center" mt={4}>
        <Pagination
          total={Math.ceil(numDeals / NUM_DEALS_PER_PAGE)}
          page={activePage}
          styles={{
            item: {
              color: "#FFFFFF",
              opacity: 0.5,
              backgroundColor: "#4D2154",
              border: "none",
              width: isDesktop ? 50 : 20,
            },
            active: {
              color: "#FFFFFF",
              fontWeight: "bold",
              opacity: 1,
              border: "1px solid #65376C",
            },
          }}
          onChange={(page) => {
            setPaginationTouched(true)
            setPage(page)
          }}
        />
      </Flex>
    </Box>
  )
}
