import { distributionChainMap } from "constants/distributionChains"
import type { NonEvmDistributionChain } from "constants/distributionChains"

import { PublicKey } from "@solana/web3.js"

import { isAlgorandAddress } from "./algorandValidator"

const validateSolanaAddress = (value: string) => {
  try {
    const key = new PublicKey(value)
    return PublicKey.isOnCurve(key.toBuffer())
  } catch (e) {
    return false
  }
}

const getValidatorForChain = (chain: NonEvmDistributionChain) => {
  switch (chain) {
    case "SOLANA":
      return validateSolanaAddress
    case "ALGORAND":
      return isAlgorandAddress
  }
}

export const validateNonEvmAddress = (chain: NonEvmDistributionChain) => (value: string) => {
  const validator = getValidatorForChain(chain)
  if (validator(value)) {
    return undefined
  }
  return `Not a valid ${distributionChainMap[chain]} address`
}
