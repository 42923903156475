import { BUSD_ADDRESS } from "constants/addresses"

import { Zero } from "@ethersproject/constants"
import { useWeb3React } from "@web3-react/core"
import { Flex } from "components/Layout.component"
import { NumericalInput } from "components/input/NumericalInput.component"
import { Button as BaseButton } from "components/legacy/Button.component"
import { H3 } from "components/legacy/typography"
import { Dots } from "components/loading/Dots.component"
import { Spinner } from "components/loading/Spinner.component"
import { useContribute } from "features/deal/contractMutations"
import { useTotalRaised, usePersonalMax, useContribution } from "features/deal/contractQueries"
import { IBackendDealInfo } from "features/deal/types"
import { tryParseAmount } from "features/farm/utils"
import { useKycStatus } from "features/notification/queries"
import { KycStatus } from "features/notification/types"
import { BigNumberMax, BigNumberMin } from "helpers/math"
import { useBusdBalance, useApproval, useTokenAllowance } from "helpers/tokenQueries"
import React from "react"
import { Link } from "react-router-dom"
import { PAGE_URLS } from "routes"

interface IContributeButtonProps {
  deal: IBackendDealInfo
}

interface IApprovalButtonProps {
  contractAddress: string
}

const ApprovalButton: React.FC<IApprovalButtonProps> = ({ contractAddress }) => {
  const { mutate: approve, isLoading: isApproving } = useApproval(BUSD_ADDRESS, contractAddress)
  return (
    <BaseButton variant="outlined" onClick={() => approve()} disabled={isApproving}>
      {isApproving ? <Dots>Approving</Dots> : "Approve"}
    </BaseButton>
  )
}

export const ContributeButton: React.FC<IContributeButtonProps> = ({ deal }) => {
  const { data: kycStatus, isLoading: isKycLoading } = useKycStatus()
  const { mutate: contribute, isLoading } = useContribute(deal)
  const { account } = useWeb3React()
  const [contributeValue, setContributeValue] = React.useState("")
  const typedContributeValue = tryParseAmount(contributeValue, 18)
  const { data: contractTotalRaised } = useTotalRaised(deal)
  const { data: personalMax } = usePersonalMax(deal)
  const { data: contribution } = useContribution(deal)
  const { data: busdBalance } = useBusdBalance()
  const { data: busdAllowance } = useTokenAllowance(BUSD_ADDRESS, deal.contractAddress)

  if (!account) {
    return null
  }

  if (kycStatus !== KycStatus.PASSED) {
    return (
      <Link to={PAGE_URLS.KYC}>
        <H3>You need to pass KYC before you can contribute. Click here to submit.</H3>
      </Link>
    )
  }

  if (!contribution || !personalMax || !busdBalance || isKycLoading) {
    return <Spinner />
  }

  // total raised is monotonically increasing
  const totalRaised = contractTotalRaised
    ? BigNumberMax(contractTotalRaised, deal.totalRaised)
    : deal.totalRaised

  const postContributeAmount = typedContributeValue && typedContributeValue.add(contribution)
  const effectiveMax = BigNumberMin(busdBalance, personalMax)
  const isAboveMin =
    typedContributeValue && typedContributeValue.add(contribution).gte(deal.minAllocation)
  const isAbovePersonalMax = postContributeAmount && postContributeAmount.gt(personalMax)
  const remaining = deal.dealSize.sub(totalRaised)
  const isLessThanRemaining = typedContributeValue && typedContributeValue.lt(remaining)
  const isAboveBusdBalance = typedContributeValue && typedContributeValue.gt(busdBalance)

  return (
    <Flex flexDirection="column">
      <Flex alignSelf="flex-end">
        <H3 fontSize="0.875rem">Your USDT balance: {busdBalance.toFixed(2)}</H3>
      </Flex>
      <Flex mt={1} width="100%" alignItems="center" position="relative">
        <NumericalInput fullWidth value={contributeValue} onUserInput={setContributeValue} />

        <BaseButton
          size="sm"
          position="absolute"
          right="0.75rem"
          onClick={() => {
            if (!busdBalance.eq(Zero)) {
              setContributeValue(effectiveMax.toFixed(18))
            }
          }}
        >
          Max
        </BaseButton>
      </Flex>
      {contributeValue && !isAboveMin && (
        <H3 fontSize="0.875rem">Minimum contribution is {deal.minAllocation.toFixed(0)}</H3>
      )}
      {contributeValue && isAboveMin && isAbovePersonalMax && (
        <H3 fontSize="0.875rem">Your maximum total contribution is {personalMax.toFixed(0)}</H3>
      )}
      {contributeValue && isAboveMin && !isAbovePersonalMax && !isLessThanRemaining && (
        <H3 fontSize="0.875rem">Amount is greater than the remaining deal size</H3>
      )}
      {contributeValue &&
        isAboveMin &&
        !isAbovePersonalMax &&
        isLessThanRemaining &&
        isAboveBusdBalance && <H3 fontSize="0.875rem">Amount is greater than your USDT balance</H3>}
      <Flex mt={3} flexDirection="column" width="100%">
        {account &&
        typedContributeValue &&
        busdAllowance &&
        busdAllowance.lt(typedContributeValue) ? (
          <ApprovalButton contractAddress={deal.contractAddress} />
        ) : (
          <BaseButton
            variant="filled"
            color="gold"
            disabled={
              isLoading ||
              !typedContributeValue ||
              !isAboveMin ||
              isAbovePersonalMax ||
              !isLessThanRemaining ||
              isAboveBusdBalance
            }
            onClick={async () => {
              contribute(contributeValue.toBigNumber(18), {
                onSuccess: () => {
                  setContributeValue("")
                },
              })
            }}
          >
            {isLoading ? <Dots>Contributing</Dots> : "Contribute"}
          </BaseButton>
        )}
      </Flex>
    </Flex>
  )
}
