import { Flex } from "components/Layout.component"
import { Title } from "components/Typography.component"
import { Trust } from "features/deal/components/Trust.component"
import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import { PAGE_URLS } from "routes"

const PAGE_NAMES = {
  [PAGE_URLS.LAUNCHPAD]: "Launchpad Deals",
  [PAGE_URLS.PRIVATE_DEALS]: "Private Deals",
  [PAGE_URLS.SALES]: "NFT Sales",
  [PAGE_URLS.ALL_DEALS]: "Deals",
}

export const DealsLayout = () => {
  const { pathname } = useLocation()

  const titleText = PAGE_NAMES[pathname]

  return (
    <Flex flexDirection="column" position="relative" width="100%">
      <Flex
        position={{ _: "relative", xs: "absolute" }}
        right={0}
        mt={{ xs: 5 }}
        mb={{ _: 2, xs: 0 }}
      >
        <Trust />
      </Flex>

      {titleText && <Title variant="h1">{titleText}</Title>}

      <Outlet />
    </Flex>
  )
}
