import { BSC_RPC_NODE_URLS } from "constants/rpc"

import { ethers } from "ethers"

// randomly pick an element from BSC_RPC_NODE_URLS
const getRandomRpcUrl = () => {
  const index = Math.floor(Math.random() * BSC_RPC_NODE_URLS.length)
  return BSC_RPC_NODE_URLS[index]
}

// used when the user does not have a wallet connected/installed on their
// browser
export const getSimpleRpcProvider = () => new ethers.providers.JsonRpcProvider(getRandomRpcUrl())

// the most advanced provider. Will seek to reach a quorum (agreement) from at
// least 50% of the nodes before returning a result.
const getFallbackRpcProvider = () => {
  const providers = []
  for (const url of BSC_RPC_NODE_URLS) {
    providers.push(new ethers.providers.JsonRpcProvider(url))
  }

  return new ethers.providers.FallbackProvider(providers)
}

// when a signer (aka library from web3 react) is not passed, we use a standard
// bsc node. This currently DOES NOT work on ETH and CANNOT be used to sign
// transactions.
// Note that this will work even if the user is on another network
// like ETH MAINNET or if they don't have a wallet connected to their browser
// It ALWAYS connects to BSC
export const getContract = (
  address: string,
  abi: any,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer ?? getSimpleRpcProvider()
  return new ethers.Contract(address, abi, signerOrProvider)
}

// Return a contract connected to a random RPC node
// When used inside of a useQuery instance and combined with auto back-off retry
// this gives a good chance of returning a result in a timely manner
export const getFastContract = (address: string, abi: any) => {
  const provider = getSimpleRpcProvider()
  return new ethers.Contract(address, abi, provider)
}

// Returns a contract connected to a quorum of RPC nodes
// This takes longer to return a result than getFastContract but the result is
// likely to be more accurate (up to date) as it relies on nodes agreeing.
// Causes more network connections than getFastContract
// not currently used
const getReliableContract = (address: string, abi: any) => {
  const provider = getFallbackRpcProvider()
  return new ethers.Contract(address, abi, provider)
}

// used to return a signer which can be passed to getContract to sign
// transactions
export const getSigner = (
  library: ethers.providers.Web3Provider,
  account: string
): ethers.providers.JsonRpcSigner => {
  return library.getSigner(account).connectUnchecked()
}
