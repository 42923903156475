import { Flex, Box } from "components/Layout.component"
import { Button as BaseButton } from "components/legacy/Button.component"
import { Panel } from "components/legacy/layout"
import { Paragraph } from "components/legacy/typography"
import React from "react"
import { MdInfo } from "react-icons/md"

interface IAboutProps {
  about: React.ReactNode
  moreInfoUrl?: string
}

export const About: React.FC<IAboutProps> = ({ about, moreInfoUrl }) => (
  <Panel icon={<MdInfo size={24} color="#FFFFFF" />} title="About">
    <Flex flexDirection="column" alignItems="flex-start">
      <Paragraph opacity={0.6}>{about}</Paragraph>
      {moreInfoUrl && (
        <Box mt={2} width="100%">
          <a href={moreInfoUrl} rel="noreferrer" target="_blank">
            <BaseButton variant="filled" color="purple" fullWidth>
              More information about this deal
            </BaseButton>
          </a>
        </Box>
      )}
    </Flex>
  </Panel>
)
