import { TIERS, ITier } from "constants/tiers"

import { useHover } from "@mantine/hooks"
import { Flex, Box } from "components/Layout.component"
import { Text, Title } from "components/Typography.component"
import { dropWhile, propEq } from "ramda"
import React from "react"
import { useTranslation, Trans } from "react-i18next"
import styled from "styled-components"

interface ITierCardProps {
  tier: ITier
  outlined?: boolean
  onClick: () => void
}

const Card = styled(Flex)`
  cursor: pointer;
`

const BASE_ALLOCATION_LAST_DEAL = 100

const TierCard: React.FC<ITierCardProps> = ({ tier, outlined, onClick }) => {
  const { name, amountSfex, gracePeriod, isNoob } = tier
  const { hovered, ref } = useHover()
  const { t } = useTranslation()

  return (
    <Card
      ref={ref}
      p={3}
      onClick={onClick}
      flexDirection="column"
      border={outlined ? "1px solid #893b96" : "none"}
      borderRadius="16px"
      bg={hovered ? "secondary" : "cardBackgroundPrimary"}
      opacity={isNoob ? 0.9 : 1}
      width={{ _: "100%", sm: "152px" }}
      height="100%"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ _: "row", sm: "column" }}
      >
        <Box order={{ sm: 1 }} mt={{ sm: 4 }}>
          <Title variant="h4">{name}</Title>
        </Box>
      </Flex>
      <Flex
        flexDirection={{ _: "row", sm: "column" }}
        alignItems="center"
        justifyContent="space-between"
        mt={{ _: 3, sm: 1 }}
        width="100%"
      >
        <Flex
          borderRight={{ _: "1px solid #65376C", sm: "none" }}
          flexDirection={{ _: "column", sm: "row" }}
          width={{ _: "50%", sm: "100%" }}
          justifyContent={{ _: "flex-start", sm: "flex-start" }}
          py={{ _: 0, sm: 2 }}
          alignItems="baseline"
        >
          <Flex alignItems="baseline" order={{ _: 0, sm: 0 }}>
            <Text fontWeight="bold" fontSize={2} shade="white">
              {amountSfex}+
            </Text>
            <Box ml={1}>
              <Text fontSize={1} color="textSecondary" fontWeight="bold">
                SFEX
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection={{ _: "column" }} alignItems="center" justifyContent="center">
          <Text
            variant={isNoob || name === "Merchant" ? "small" : "smallsubtitle"}
            opacity={isNoob || name === "Merchant" ? 0.4 : 1}
          >
            {isNoob ? t("Some deals") : name === "Merchant" ? t("Most deals") : t("All deals")}
          </Text>
          {gracePeriod ? (
            <Text variant="smallsubtitle" color="gold" opacity={1}>
              {t("Early access")}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Card>
  )
}
interface ITiersProps {
  hideIntro?: boolean
}
export const Tiers: React.FC<ITiersProps> = ({ hideIntro }) => {
  const [selectedIdx, setSelectedIdx] = React.useState(3)
  const [clicked, setClicked] = React.useState(false)
  const { t } = useTranslation()

  const availableTiers = dropWhile(propEq("name", "Peddler"), TIERS)

  React.useEffect(() => {
    if (clicked) {
      return
    }
    const stop = setInterval(
      () =>
        selectedIdx === availableTiers.length - 1
          ? setSelectedIdx(0)
          : setSelectedIdx(selectedIdx + 1),
      3000
    )

    return () => clearInterval(stop)
  }, [setSelectedIdx, selectedIdx, clicked, availableTiers])

  const tier = availableTiers[selectedIdx]
  const contributionMax = tier.allocationMultiplier * BASE_ALLOCATION_LAST_DEAL

  return (
    <Flex flexDirection="column" alignItems="center">
      {!hideIntro && (
        <>
          <Box mt={{ _: 7 }}>
            <Title variant="h2" textAlign="center">
              {t("How to invest")}
            </Title>
          </Box>
          <Box mt={{ _: 2 }} maxWidth={{ sm: "50%" }}>
            <Text textAlign={{ _: "center", sm: "center" }}>
              {t(
                "Buy & stake our SFEX token to gain access to deals and NFT sales. The more you stake, the more you can invest in future deals."
              )}
            </Text>
          </Box>
        </>
      )}
      <Flex
        mt={{ _: 6, sm: 6 }}
        width={{ _: "100%", xs: "80%", md: "100%" }}
        flexDirection={{ _: "column", sm: "row" }}
        justifyContent="center"
        flexWrap={{ xs: "wrap", md: "nowrap" }}
        height={{ md: "214px" }}
      >
        {availableTiers.map((t, i) => (
          <Box mb={{ _: 3, sm: 0 }} ml={{ _: 0, sm: 1 }} key={t.name}>
            <TierCard
              onClick={() => {
                setSelectedIdx(i)
                setClicked(true)
              }}
              outlined={selectedIdx === i}
              tier={t}
            />
          </Box>
        ))}
      </Flex>
      <Flex mt={3} flexDirection="column" alignItems="flex-start" minWidth={{ sm: "600px" }}>
        <Title variant="h5">Example</Title>
        <Box mt={1} height="80px">
          <Text textAlign="left">
            <Trans>
              Stake {{ amountSfex: tier.amountSfex }}+ SFEX or{" "}
              {{ amountSfexBnbLp: tier.amountSfexBnbLp }}+ SFEX-BNB LP to be a{" "}
              <Text as="span" fontWeight="bold" opacity={1}>
                {{ tierName: tier.name }}
              </Text>
            </Trans>
          </Text>
          <Text textAlign="left">
            <Trans>
              This would mean you&apos;d be able to contribute up to{" "}
              <Text as="span" fontWeight="bold" opacity={1} color="textPrimary">
                ${{ contributionMax }}
              </Text>{" "}
              in our last deal
            </Trans>
          </Text>
          {tier.gracePeriod && (
            <Text>
              <Trans>
                You would also have{" "}
                <Text as="span" color="gold" fontWeight="bold" opacity={1}>
                  early access
                </Text>{" "}
                to the deal five minutes before everyone else
              </Trans>
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
