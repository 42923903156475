import { Flex } from "components/Layout.component"
import React from "react"
import { OrbitSpinner as Spinner } from "react-epic-spinners"
import styled from "styled-components"

type ColorType = "default" | "dark"

interface IButtonProps {
  color?: ColorType
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  loading?: boolean
  fullWidth?: boolean
  large?: boolean
  [x: string]: any
}

const ButtonContainer = styled(Flex)<{ isLoading?: boolean }>`
  text-transform: uppercase;
  &:disabled {
    border: none;
    opacity: ${(props) => (props.isLoading ? 1 : 0.3)};
    cursor: not-allowed;
  }
`

export const Button: React.FC<IButtonProps> = ({
  children,
  color = "default",
  leftIcon,
  rightIcon,
  loading,
  fullWidth,
  large = false,
  disabled,
  ...rest
}) => {
  const darkOrLoading = color === "dark" || loading

  return (
    <ButtonContainer
      {...rest}
      as="button"
      flexDirection="column"
      position="relative"
      width={fullWidth ? "100%" : "auto"}
      isLoading={loading}
      disabled={loading || disabled}
    >
      <Flex
        py={large ? "18px" : "12px"}
        px={large ? "20px" : "12px"}
        width={fullWidth ? "100%" : "auto"}
        borderRadius="8px"
        height="100%"
        color="textPrimary"
        fontSize="small"
        lineHeight="120%"
        fontWeight="bold"
        backgroundColor={darkOrLoading ? "backgroundSecondary" : "primary"}
        border={darkOrLoading ? "1.5px solid #893B96" : "none"}
        alignItems="center"
        justifyContent="center"
      >
        {leftIcon && (
          <Flex mr={2} alignItems="center">
            {leftIcon}
          </Flex>
        )}
        {children}
        {rightIcon && (
          <Flex ml={2} alignItems="center">
            {rightIcon}
          </Flex>
        )}
      </Flex>
      {loading && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          backgroundColor={darkOrLoading ? "backgroundSecondary" : "primary"}
        >
          <Spinner color="#1FCC92" size={20} />
        </Flex>
      )}
    </ButtonContainer>
  )
}
