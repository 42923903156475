import React from "react"
import styled from "styled-components"
import {
  color,
  opacity,
  OpacityProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

type FontProps = TypographyProps &
  SpaceProps &
  BorderProps &
  OpacityProps &
  LayoutProps & { color?: string }

const ParagraphBase = styled.p<FontProps>`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  ${typography};
  ${space};
  ${layout};
  ${border};
  ${color};
  ${opacity};
`
export const Paragraph: React.FC<FontProps> = (props) => (
  <ParagraphBase
    fontSize={{ _: "16px", tablet: "18px" }}
    lineHeight={{ _: "24px", tablet: "30px" }}
    {...props}
  />
)
const BoldBase = styled.span<FontProps>`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  ${typography};
  ${space};
  ${layout};
  ${border};
  ${color};
`

export const Bold: React.FC<TypographyProps> = (props) => (
  <BoldBase
    fontSize={{ _: "16px", tablet: "18px" }}
    lineHeight={{ _: "24px", tablet: "30px" }}
    fontWeight={550}
    {...props}
  />
)

const H1Base = styled.h1<TypographyProps>`
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 75px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  ${typography};
`
export const H1: React.FC<TypographyProps> = (props) => (
  <H1Base
    fontSize={{ _: "35px", tablet: "50px" }}
    lineHeight={{ _: "35px", tablet: "64px" }}
    {...props}
  />
)

const H2Base = styled.h2<TypographyProps>`
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  ${typography};
`
export const H2: React.FC<TypographyProps> = (props) => (
  <H2Base
    fontSize={{ _: "18px", tablet: "22px" }}
    lineHeight={{ _: "24px", tablet: "35px" }}
    {...props}
  />
)

export const H3 = styled.h3<FontProps>`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #b6b6b6;
  ${typography};
  ${space};
  ${layout};
  ${border};
  ${color};
`

export const Gold = styled(Bold)`
  color: #f59e0b;
`
