import { Box } from "components/Layout.component"
import { Tiers } from "features/tiers/components/Tiers.component"
import React from "react"

import { Hero } from "./components/Hero.component"
import { LiveAndUpcoming } from "./components/offering/LiveAndUpcoming.component"

const Home: React.FC = () => {
  return (
    <Box mt={{ _: 7, md: 11 }}>
      <Hero />
      <Tiers />
      <Box mt={11} mx="auto">
        <LiveAndUpcoming />
      </Box>
    </Box>
  )
}

export default Home
